import React, { useState } from "react";
import Modal from "react-modal"; // Modal ko import kiya
import "./UploadVideoIntro.css";
import uploadImg from "../../../../../assets/imgs/uploadIcon.png";
import fileImg from "../../../../../assets/imgs/file.png";
import { toast, ToastContainer } from "react-toastify";
Modal.setAppElement("#root"); // Accessibility ke liye zaroori hai

const UploadVideoIntro = ({ isOpen, onClose, candidate }) => {
  const [video, setVideo] = useState(null); // Video state rakhi
  const [uploading, setUploading] = useState(false); // Upload progress ke liye state
  const [progress, setProgress] = useState(0); // Progress bar ke liye
  const [error, setError] = useState(""); // Error handling ke liye

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      width: "45%",
      marginRight: "-50%",
      position: "absolute",
      transform: "translate(-50%, -50%)",
      borderRadius: "10px",
      padding: "0px",
    },
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (
      file &&
      (file.type === "video/mp4" || file.type === "video/quicktime")
    ) {
      setError("");
      setVideo(file); // Video file ko state mein store kar diya
    } else {
      setError("You can only upload .mp4 or .mov formats.");
    }
  };

  const handleSaveChanges = () => {
    if (!video) {
      setError("Please select a video first.");
      return;
    }

    setUploading(true);
    const formData = new FormData();
    formData.append("profileVideo", video);

    const xhr = new XMLHttpRequest();
    xhr.open(
      "POST",
      `http://localhost:5000/api/candidates/uploadVideo/${candidate._id}`
    );

    // Track progress
    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentComplete = (event.loaded / event.total) * 100;
        setProgress(percentComplete);
      }
    };

    xhr.onload = () => {
      if (xhr.status === 200) {
        setProgress(100); // Set progress to 100% for UI update
        setTimeout(() => {
          setUploading(false); // Stop uploading state
          onClose(); // Close the modal after a delay
        }, 2000); // 1000 ms (1 second) delay before closing the modal
        toast.success("Candidate updated successfully!");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        setError("Error uploading video");
        setUploading(false);
      }
    };

    xhr.onerror = () => {
      toast.error("Error updating candidate: " + error.message);
      setError("Failed to upload video: Unknown error");
      setUploading(false);
    };

    xhr.send(formData);
  };

  return (
    <div>
      <ToastContainer />
      <Modal
        isOpen={isOpen}
        contentLabel="Upload Video"
        onRequestClose={onClose}
        style={customStyles}
      >
        <div className="popup-content">
          <button onClick={onClose} className="close-btn">
            X
          </button>

          <div className="popup-header">
            <h2>Upload or Edit Your Video</h2>
            <p>
              Upload a clear, professional video to showcase yourself to hiring
              companies.
            </p>
          </div>
          <hr />
          <div className="upload-heading">
            <h6>Upload Your Video</h6>
          </div>
          <div className="row" style={{ alignItems: "center" }}>
            <div className="col-lg-6">
              <div
                className="upload-box"
                onDragOver={(e) => e.preventDefault()}
                onDrop={(e) => {
                  e.preventDefault();
                  handleFileChange({
                    target: { files: e.dataTransfer.files },
                  });
                }}
              >
                {video ? (
                  <div className="video-preview">
                    <video
                      controls
                      src={URL.createObjectURL(video)}
                      alt="Preview"
                    />
                  </div>
                ) : (
                  <>
                    <img
                      src={uploadImg}
                      alt="upload-img"
                      className="uploadImg"
                    />
                    <p
                      style={{
                        padding: "10px",
                        fontWeight: "600",
                        fontSize: "16px",
                        paddingBottom: "0px",
                        margin: "0px",
                      }}
                    >
                      Drag & drop files or{" "}
                      <label
                        htmlFor="file-upload"
                        className="browse-link"
                        style={{ cursor: "pointer " }}
                      >
                        <b> Browse</b>
                      </label>
                    </p>
                    <p style={{ fontSize: "12px" }}>
                      Supported formats: .mp4, .mov
                    </p>
                  </>
                )}
                <input
                  type="file"
                  id="file-upload"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
              </div>
            </div>

            <div className="col-lg-6">
              <div className="left-content-popup">
                <h5 className="popup-left-heading">
                  Show companies the best version of yourself!
                </h5>
                <p style={{ marginBottom: "0px" }}>
                  Must be an actual video of you. Logos, clip-art, group videos,
                  and digitally-altered videos are not allowed.
                </p>
              </div>
            </div>
          </div>

          {/* Progress bar */}
          {uploading && (
            <div
              className="progress-bar "
              style={{ width: "100%", marginRight: "20px" }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div>
                  <img src={fileImg} alt="fileImg" />
                </div>

                <div>
                  <p style={{ marginBottom: "0px", paddingBottom: "0px" }}>
                    Uploading...
                  </p>

                  <b style={{ marginBottom: "5px" }}>{video.name}</b>
                </div>
              </div>
              <div style={{ marginTop: "10px" }}>
                <div
                  className="progress"
                  style={{ width: `${progress}%` }}
                ></div>
              </div>
            </div>
          )}
          {error && <p className="error-message">{error}</p>}

          <hr style={{ marginTop: "30px" }} />

          <div className="form-btn">
            <button onClick={handleSaveChanges}>Save Changes</button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default UploadVideoIntro;
