import React, { useState } from "react";
import "./Certification.css";
import certificationIcon from "../../../../assets/imgs/certification.png";
import plusIcon from "../../../../assets/imgs/plusCircle.png";
import AddCertificate from "./AddCertificate/AddCertificate";
import editIcon from "../../../../assets/imgs/Edit.png";

function Certification({ candidate, hideEditIcon }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Modal open karne ka function
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Modal band karne ka function
  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div className="certification">
      <h4>Certifications</h4>

      <div className="certificate-sections-list">
        {(candidate.certifications && candidate.certifications.length > 0) ||
        (candidate.Pursuingcertifications &&
          candidate.Pursuingcertifications.length > 0) ? (
          <div className="exist-target-value">
            {!hideEditIcon && (
              <div className="editIcon" onClick={openModal}>
                <img src={editIcon} alt="Edit-icon" />
              </div>
            )}
            <div className="certification-list-options">
              {candidate.certifications.map((industry, index) => (
                <span key={index} className="industry-tag">
                  {industry}
                </span>
              ))}
            </div>

            <div className="certification-list-options">
              {candidate.Pursuingcertifications.map((industry, index) => (
                <span key={index} className="industry-tag">
                  {`${industry} (In-Progress)`}
                </span>
              ))}
            </div>
          </div>
        ) : (
          <div className="empty-state">
            <div className="plusIcon" onClick={openModal}>
              <img src={plusIcon} alt="plus-icon" />
            </div>
            <img src={certificationIcon} alt="certification" />
            <p>
              Highlight your skills and qualifications by adding any relevant
              certifications. Stand out to companies
              <br /> looking for your expertise.
            </p>
            <button onClick={openModal}>Add Certifications</button>
          </div>
        )}
      </div>

      <AddCertificate
        isOpen={isModalOpen}
        onClose={closeModal}
        candidate={candidate}
      />
    </div>
  );
}

export default Certification;
