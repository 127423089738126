import React, { useState, useContext } from "react";
import Modal from "react-modal";
import { AuthContext } from "../../../../context/AuthContext";
import { toast, ToastContainer } from "react-toastify";
import "./JobSearchStatus.css";

Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "45%",
    marginRight: "-50%",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
    padding: "0px",
  },
};

function UpdateJobSearchSlider({ isOpen, onClose, candidate }) {
  const { user } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    fname: candidate.fname || "",
    lname: candidate.lname || "",
    email: user.email || "",
    phone: candidate.phone || "",
    idno: candidate.recruiterFlowId || "",
    state: candidate.state || "",
    city: candidate.city || "",
    candidateType: candidate.type,
    idealJobTitle: candidate.idealJobTitle || "",
    branch: candidate.branch,
    description: candidate.description || "",
    securityClearance: candidate.securityClearance || "",
    yearsOfExperience: candidate.yearsOfExperience || "",
    skillbridgeStartDate: candidate.skillbridgeStartDate || "",
    skillbridgeEndDate: candidate.skillbridgeEndDate || "",
    duringSkillbridgeCity: candidate.duringSkillbridgeCity || "",
    duringSkillbridgeState: candidate.duringSkillbridgeState || "",
    afterskillbridgeCity: candidate.afterskillbridgeCity || "",
    afterskillbridgeState: candidate.afterskillbridgeState || "",
    relocationprefrence: candidate.relocationprefrence || "",
    education: candidate.education || "",
    jobSearchStatus: candidate.jobSearchStatus || "3",
    jobSearchStatusName:
      candidate.jobSearchStatusName ||
      "Casually Exploring – Browsing opportunities.", // Default slider value
    branch: candidate.branch || "", // Optional chaining
    rank: candidate.rank || "",
    mos: candidate.mos || "",
    yearsServed: candidate.yearsServed || "",
    certifications: candidate.certifications || "",
    targetIndustries: candidate.targetIndustries || "",
  });

  // Handle input changes (including the slider)
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "jobSearchStatus") {
      const statusIndex = Number(value) - 1;
      setFormData({
        ...formData,
        [name]: Number(value), // Slider value as a number
        jobSearchStatusName: values[statusIndex], // Update the corresponding name
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        `http://localhost:5000/api/candidates/${candidate._id}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${user.token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update candidate");
      }

      const updatedCandidate = await response.json();
      toast.success("Candidate updated successfully!");
      onClose();
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      toast.error("Error updating candidate: " + error.message);
    }
  };

  const values = [
    "Just Looking",
    "Open to Ideas",
    "Casually Exploring – Browsing opportunities.",
    "Actively Searching",
    "Ready to Make a Move",
  ];

  return (
    <>
      <ToastContainer />
      <Modal
        isOpen={isOpen}
        style={customStyles}
        onRequestClose={onClose}
        contentLabel="Update Basic Information"
      >
        <button onClick={onClose} className="close-btn">
          X
        </button>

        <form onSubmit={handleSubmit} className="popup-content">
          <div className="popup-header">
            <h2>Adjust Your Job Search Status</h2>
            <p>
              Move the slider to reflect how actively you’re searching for a new
              role.
            </p>
          </div>
          <hr />
          <div className="search-slider-job">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
                padding: "20px",
                alignItems: "center",
              }}
              className="slider-num"
            >
              {[1, 2, 3, 4, 5].map((num) => (
                <span
                  key={num}
                  className={
                    formData.jobSearchStatus === num ? "active-number" : ""
                  }
                >
                  {num}
                </span>
              ))}
            </div>
            {/* Job Search Status Slider */}
            <input
              type="range"
              name="jobSearchStatus"
              min="1"
              max="5"
              value={formData.jobSearchStatus}
              onChange={handleChange}
              step="1"
              style={{ width: "100%" }}
            />

            {/* Display the current slider description */}
            <div style={{ textAlign: "center", marginBottom: "20px" }}>
              <h4 className="pointer-label">
                {values[formData.jobSearchStatus - 1]}
              </h4>{" "}
              {/* Based on slider value */}
            </div>
          </div>
          <hr />
          {/* Submit Button */}
          <div className="form-btn">
            <button type="submit" className="primary-btn">
              Save Changes
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
}

export default UpdateJobSearchSlider;
