import React, { useState, useContext } from "react";
import Modal from "react-modal";
import "./SelectTargetIndustries.css";
import { toast, ToastContainer } from "react-toastify";
import { industriesList } from "./IndustriesList.js";
import { AuthContext } from "../../../../../context/AuthContext";
Modal.setAppElement("#root");

const SelectTargetIndustries = ({ isOpen, onClose, candidate }) => {
  const { user } = useContext(AuthContext);
  const [currentTargets, setCurrentTargets] = useState(
    candidate.targetIndustries || []
  );

  const [formData, setFormData] = useState({
    fname: candidate.fname || "",
    lname: candidate.lname || "",
    candidateType: candidate.type || "",
    state: candidate.state || "",
    city: candidate.city || "",
    branch: candidate.branch || "",
    description: candidate.description || "",
    securityClearance: candidate.securityClearance || "",
    yearsOfExperience: candidate.yearsOfExperience || "",
    skillbridgeStartDate: candidate.skillbridgeStartDate || "",
    skillbridgeEndDate: candidate.skillbridgeEndDate || "",
    idealJobTitle: candidate.idealJobTitle || "",
    duringSkillbridgeCity: candidate.duringSkillbridgeCity || "",
    duringSkillbridgeState: candidate.duringSkillbridgeState || "",
    afterskillbridgeCity: candidate.afterskillbridgeCity || "",
    afterskillbridgeState: candidate.afterskillbridgeState || "",
    relocationprefrence: candidate.relocationprefrence || "",
    education: candidate.education || "",
    idno: candidate.recruiterFlowId || "", // Yeh ID RecruiterFlow ki hai
    email: candidate.email || "",
    phone: candidate.phone || "",
    certifications: candidate.certifications || "",
    Pursuingcertifications: candidate.Pursuingcertifications || "",
    branch: candidate.branch || "", // Optional chaining
    rank: candidate.rank || "",
    mos: candidate.mos || "",
    yearsServed: candidate.yearsServed || "",
    targetIndustries: currentTargets, // initialize with existing target industries
  });

  // Function to toggle the selection of target industries
  const handleTargetClick = (industry) => {
    if (currentTargets.includes(industry)) {
      const updatedTargets = currentTargets.filter(
        (target) => target !== industry
      );
      setCurrentTargets(updatedTargets);
      setFormData({ ...formData, targetIndustries: updatedTargets });
    } else {
      const updatedTargets = [...currentTargets, industry];
      setCurrentTargets(updatedTargets);
      setFormData({ ...formData, targetIndustries: updatedTargets });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    try {
      const response = await fetch(
        `http://localhost:5000/api/candidates/${candidate._id}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${user.token}`, // Check user token
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...formData,
            targetIndustries: currentTargets, // Ensure target industries are sent correctly
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update candidate");
      }

      const updatedCandidate = await response.json();
      toast.success("Candidate updated successfully!"); // Success message
      onClose(); // Close the modal
      setTimeout(() => {
        window.location.reload();
      }, 1000); // Optionally reload the page after update
    } catch (error) {
      toast.error("Error updating candidate: " + error.message);
    }
  };

  return (
    <>
      <ToastContainer />

      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        className="target-modal"
        overlayClassName="target-modal-overlay"
      >
        <div className="target-popup popup-content">
          <button onClick={onClose} className="close-btn">
            X
          </button>

          <div className="popup-header">
            <h2>Edit Your Target Industries</h2>
            <p>
              Adjust your preferred industries to attract the right job
              opportunities.
            </p>
          </div>
          <hr />

          <div className="target-heading">
            <h4>Select Your Target Industries</h4>

            <div className="industries-list target-section">
              {industriesList.map((industry, index) => (
                <span
                  key={index}
                  className={`tag ${
                    currentTargets.includes(industry) ? "active" : ""
                  }`}
                  onClick={() => handleTargetClick(industry)}
                >
                  {industry}
                  {currentTargets.includes(industry) && (
                    <button
                      className="remove-btn"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevents triggering the main tag click
                        handleTargetClick(industry);
                      }}
                    >
                      x
                    </button>
                  )}
                </span>
              ))}
            </div>
          </div>

          <hr style={{ marginTop: "30px" }} />

          <div className="form-btn">
            <button onClick={handleSubmit}>Save Changes</button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SelectTargetIndustries;
