import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Ensure you have react-icons installed

import otpImg from "../../../assets/imgs/otp-email.png";
import alertImg from "../../../assets/imgs/alert.png";
import trueImg from "../../../assets/imgs/true.png";
import GoogleLoginButton from "./GoogleLogin";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const RegistrationForm = ({
  step,
  formData,
  handleChange,
  handleRegister,
  showPassword,
  togglePasswordVisibility,
  sendOtp,

  handleOtpChange,
  setStep,
  otp,
}) => {
  const [branch, setBranch] = useState("");
  const [userData, setUserData] = useState();

  const handleLoginSuccess = async (data) => {
    setUserData(data);
  };

  useEffect(() => {
    if (userData) {
    }
  }, [userData]);

  const googleCreateAccount = async () => {
    const mergedData = {
      ...userData,
      branch: formData.branch,
      type: formData.candidateType,
    };

    setUserData(mergedData);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/api/users/google-register-candidate`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(mergedData),
        }
      );

      if (res.ok) {
        const data = await res.json();
        toast.success("User registered successfully!");
        setStep(4);
      } else {
        const data = await res.json();
        if (data.message === "User already exists!") {
          toast.error("User already exists!");
          setStep(3);
        } else {
          toast.error("User Registration Failed!");
        }
      }
    } catch (error) {
      toast.error("Error: Something went wrong!");
      console.error("Error:", error);
    }
  };

  return (
    <form onSubmit={handleRegister}>
      <div className="company-fields">
        {step === 1 && (
          <div className="1st-step-fields">
            <h4>Let’s Get You Registered</h4>
            <p style={{ textAlign: "center", fontSize: "14px" }}>
              Join our growing community of top candidates. Set up your profile
              and connect with hiring companies looking for talent like you!
            </p>
            <div className="google-btn">
              <GoogleLoginButton
                setStep={setStep}
                onSuccess={handleLoginSuccess}
              />
            </div>
            <p className="google-link-or"> or </p>
            <div className="row-combind">
              <div className="name-wrap field-combind">
                <label>
                  First Name <span>*</span>
                </label>
                <input
                  type="text"
                  name="fname"
                  placeholder="Type here"
                  value={formData.fname}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="title-wrap field-combind">
                <label>
                  Last Name <span>*</span>
                </label>
                <input
                  type="text"
                  name="lname"
                  placeholder="Type here"
                  value={formData.lname}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="row-combind">
              <div className="phone-wrap field-combind">
                <label>
                  Phone No <span>*</span>
                </label>
                <input
                  type="text"
                  name="phone"
                  placeholder="Type here"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="company-wrap field-combind">
                <label>
                  Military Branch <span>*</span>
                </label>
                <input
                  type="text"
                  name="branch"
                  placeholder="Type here"
                  value={formData.branch}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className="field-wrap">
              <label>
                Describe Yourself <span>*</span>
              </label>
              <div className="checkbox-main">
                <div
                  className="checkboxs"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="radio"
                    value="Transitioning Service Member"
                    checked={
                      formData.candidateType === "Transitioning Service Member"
                    }
                    name="candidateType"
                    onChange={handleChange} // Save the value using the handleChange
                    style={{ width: "20px", marginRight: "10px" }}
                  />
                  <span>Transitioning Service Member</span>
                </div>

                <div
                  className="checkboxs"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="radio"
                    value="Veteran"
                    name="candidateType"
                    checked={formData.candidateType === "Veteran"}
                    onChange={handleChange} // Save the value using the handleChange
                    style={{ width: "20px", marginRight: "10px" }}
                  />
                  <span>Veteran</span>
                </div>
              </div>
            </div>
            <div className="field-wrap">
              <label>
                Email <span>*</span>
              </label>
              <input
                type="email"
                name="email"
                placeholder="Type here"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="field-wrap">
              <label>
                Password <span>*</span>
              </label>
              <div className="password-field">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="Password (5 or more characters)"
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
                <span
                  onClick={togglePasswordVisibility}
                  className="password-toggle-icon"
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>
            </div>
            <div className="submit-form">
              <button
                className="company-register-btn"
                type="button"
                onClick={sendOtp}
              >
                Create Free Account Today
              </button>
            </div>
          </div>
        )}

        {step === 2 && (
          <div className="2nd-step-fields otp-step">
            <div className="otp-img">
              <img src={otpImg} className="otp-img" alt="otp" />
            </div>
            <h2>Check Your Email</h2>
            <p className="otp-text">
              Please enter the 6 digit OTP sent to your email.
            </p>
            <div
              className="otp-inputs"
              style={{ display: "flex", justifyContent: "center", gap: "10px" }}
            >
              {otp.map((digit, index) => (
                <input
                  key={index}
                  type="text"
                  maxLength="1"
                  value={digit}
                  onChange={(e) => handleOtpChange(e.target, index)}
                  style={{
                    width: "50px",
                    height: "50px",
                    textAlign: "center",
                    fontSize: "24px",
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                  }}
                />
              ))}
            </div>
            <p style={{ padding: "20px 0px 0px 0px" }}>
              Didn’t receive an email?{" "}
              <span style={{ fontWeight: 500, cursor: "pointer" }}>
                Resend Email
              </span>
            </p>
            <div name="prev-next-buttons">
              <button className="btn confirm-btn" type="submit">
                Confirm Account
              </button>
            </div>
          </div>
        )}

        {step === 3 && (
          <div className="already-registed-box">
            <img src={alertImg} className="alertImg" />
            <h3>Account Already Registered</h3>
            <p>
              The email address you provided <b>{formData.email}</b> is already
              registered in our database. If you would like to regain access to
              your account.
            </p>

            <Link to="/candidate/login" className="Login-btn-done">
              Login Your Account!
            </Link>
          </div>
        )}

        {step === 6 && (
          <div className="already-registed-box">
            <img src={alertImg} className="alertImg" />
            <h3>This account is already registered, but not with Google.</h3>
            <p>
              The email address you provided <b>{formData.email}</b> is already
              registered in our database. If you would like to regain access to
              your account.
            </p>

            <Link to="/candidate/login" className="Login-btn-done">
              Login Your Account!
            </Link>
          </div>
        )}
        {step === 4 && (
          <div className="done-registed-box">
            <img src={trueImg} className="trueImg" />
            <h3>Account Successfully Registered</h3>
            <p>You can now start using your account!</p>
            <div>
              <Link to="/candidate/login" className="Login-btn-done">
                Login Your Account!
              </Link>
            </div>
          </div>
        )}

        {step === 5 && (
          <div className="done-registed-box">
            <img src={trueImg} className="trueImg" />
            <h3>You’re Almost There!</h3>
            <p>
              Your Google account has been successfully connected. Complete your
              registration by providing your candidate details
            </p>

            <div className="google-btn">
              <GoogleLoginButton
                setStep={setStep}
                onSuccess={handleLoginSuccess}
              />
            </div>

            <div className="company-wrap field-combind">
              <label>
                Military Branch <span>*</span>
              </label>
              <input
                type="text"
                name="branch"
                placeholder="Type here"
                value={formData.branch}
                onChange={handleChange}
                required
              />
            </div>

            <label>
              Candidate Type <span>*</span>
            </label>

            <div className="checkbox-main">
              <div
                className="checkboxs"
                style={{ display: "flex", alignItems: "center" }}
              >
                <input
                  type="radio"
                  value="Transitioning Service Member"
                  checked={
                    formData.candidateType === "Transitioning Service Member"
                  }
                  name="candidateType"
                  onChange={handleChange} // Save the value using the handleChange
                  style={{ width: "20px", marginRight: "10px" }}
                />
                <span>Transitioning Service Member</span>
              </div>

              <div
                className="checkboxs"
                style={{ display: "flex", alignItems: "center" }}
              >
                <input
                  type="radio"
                  value="Veteran"
                  name="candidateType"
                  checked={formData.candidateType === "Veteran"}
                  onChange={handleChange} // Save the value using the handleChange
                  style={{ width: "20px", marginRight: "10px" }}
                />
                <span>Veteran</span>
              </div>
            </div>

            <button
              className="company-register-btn"
              type="button"
              onClick={googleCreateAccount}
            >
              Create Free Account Today
            </button>

            <p className="login-link selection-login">
              Already have an account? <Link to="/candidate/login">Login</Link>
            </p>
          </div>
        )}
      </div>
    </form>
  );
};

export default RegistrationForm;
