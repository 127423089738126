import React, { useState, useContext } from "react";
import Modal from "react-modal";
import "./UpdateBasicInformationPopup.css";
import { AuthContext } from "../../../../context/AuthContext";
import { toast, ToastContainer } from "react-toastify";
import { statesWithCities } from "./LocationList";
import InputMask from "react-input-mask"; // Importing react-input-mask

Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "45%",
    marginRight: "-50%",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
    padding: "0px",
  },
};

function UpdateBasicInformation({ isOpen, onClose, candidate }) {
  const { user } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    fname: candidate.fname || "",
    lname: candidate.lname || "",
    email: user.email || "",
    phone: candidate.phone || "",
    idno: candidate.recruiterFlowId || "",
    state: candidate.state || "",
    city: candidate.city || "",
    candidateType: candidate.type,
    idealJobTitle: candidate.idealJobTitle || "",
    branch: candidate.branch || "",
    description: candidate.description || "",
    securityClearance: candidate.securityClearance || "",
    yearsOfExperience: candidate.yearsOfExperience || "",
    skillbridgeStartDate: candidate.skillbridgeStartDate || "",
    skillbridgeEndDate: candidate.skillbridgeEndDate || "",
    duringSkillbridgeCity: candidate.duringSkillbridgeCity || "",
    duringSkillbridgeState: candidate.duringSkillbridgeState || "",
    afterskillbridgeCity: candidate.afterskillbridgeCity || "",
    afterskillbridgeState: candidate.afterskillbridgeState || "",
    relocationprefrence: candidate.relocationprefrence || "",
    education: candidate.education || "",
    rank: candidate.rank || "",
    mos: candidate.mos || "",
    yearsServed: candidate.yearsServed || "",
    targetIndustries: candidate.targetIndustries || "",
    certifications: candidate.certifications || "",
  });

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle state change and reset city when state changes
  const handleStateChange = (e) => {
    const selectedState = e.target.value;
    setFormData({
      ...formData,
      state: selectedState,
      city: "", // Reset city when state is changed
    });
  };

  // Handle city change
  const handleCityChange = (e) => {
    setFormData({ ...formData, city: e.target.value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if required fields are filled
    if (!formData.fname || !formData.lname) {
      toast.error("First and Last Name are required.");
      return;
    }

    try {
      const response = await fetch(
        `http://localhost:5000/api/candidates/${candidate._id}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${user.token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update candidate");
      }

      const updatedCandidate = await response.json();
      toast.success("Candidate updated successfully!");
      onClose();
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      toast.error("Error updating candidate: " + error.message);
    }
  };

  return (
    <>
      <ToastContainer />
      <Modal
        isOpen={isOpen}
        style={customStyles}
        onRequestClose={onClose}
        contentLabel="Update Basic Information"
      >
        <button onClick={onClose} className="close-btn">
          X
        </button>

        <div className="popup-content">
          <div className="popup-header">
            <h2>Edit Your Basic Information</h2>
            <p>
              Ensure your contact details are up to date so companies can easily
              reach you.
            </p>
          </div>
          <hr />

          <form className="popup-form" onSubmit={handleSubmit}>
            <div className="two-fields">
              <div className="form-field">
                <label>
                  First Name <span>*</span>
                </label>
                <input
                  type="text"
                  placeholder="Type Here"
                  name="fname"
                  value={formData.fname}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-field">
                <label>
                  Last Name <span>*</span>
                </label>
                <input
                  type="text"
                  placeholder="Type Here"
                  name="lname"
                  value={formData.lname}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className="two-fields">
              <div className="form-field">
                <label>
                  Email <span>*</span>
                </label>
                <input
                  type="email"
                  placeholder="abc@7eagle.com"
                  name="email"
                  value={formData.email}
                  readOnly
                  style={{ backgroundColor: "#f6f6f6" }}
                />
              </div>
            </div>

            <div className="two-fields">
              <div className="form-field">
                <label>
                  Phone <span>*</span>
                </label>
                <InputMask
                  mask="(999) 999-9999" // Masking for USA phone number
                  placeholder="(123) 456-7890"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="form-input"
                />
              </div>

              <div className="form-field">
                <label>
                  ID Number <span>*</span>
                </label>
                <input
                  type="text"
                  placeholder="Type Here"
                  name="idno"
                  value={formData.idno}
                  readOnly
                  style={{ backgroundColor: "#f6f6f6" }}
                />
              </div>
            </div>

            <div className="two-fields">
              <div className="form-field">
                <label>
                  State <span>*</span>
                </label>
                <select
                  name="state"
                  value={formData.state}
                  onChange={handleStateChange}
                >
                  <option value="">Select State</option>
                  {Object.keys(statesWithCities).map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-field">
                <label>
                  City <span>*</span>
                </label>
                <select
                  name="city"
                  value={formData.city}
                  onChange={handleCityChange}
                  disabled={!formData.state} // Disable until state is selected
                >
                  <option value="">Select City</option>
                  {formData.state &&
                    statesWithCities[formData.state].map((city) => (
                      <option key={city} value={city}>
                        {city}
                      </option>
                    ))}
                </select>
              </div>
            </div>

            {/* Candidate Type Radio Buttons */}
            <div className="field-wrap form-field">
              <label>
                Describe Yourself <span>*</span>
              </label>
              <div className="checkbox-main">
                <div
                  className="checkboxs"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="radio"
                    value="Transitioning Service Member"
                    checked={
                      formData.candidateType === "Transitioning Service Member"
                    }
                    name="candidateType"
                    onChange={handleChange}
                    style={{ width: "20px", marginRight: "10px" }}
                  />
                  <span>Transitioning Service Member</span>
                </div>

                <div
                  className="checkboxs"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="radio"
                    value="Veteran"
                    name="candidateType"
                    checked={formData.candidateType === "Veteran"}
                    onChange={handleChange}
                    style={{ width: "20px", marginRight: "10px" }}
                  />
                  <span>Veteran</span>
                </div>
              </div>
            </div>

            <hr style={{ marginTop: "40px" }} />

            <div className="form-btn">
              <button type="submit" className="primary-btn">
                Save Changes
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}

export default UpdateBasicInformation;
