import React, { useState } from "react";
import "./TargetIndustries.css";
import settingIcon from "../../../../assets/imgs/settingIcon.png";
import plusIcon from "../../../../assets/imgs/plusCircle.png";
import SelectTargetIndustries from "./SelectTargetIndustries/SelectTargetIndustries";
import editIcon from "../../../../assets/imgs/Edit.png";
function TargetIndustries({ candidate, hideEditIcon }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Modal open karne ka function
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Modal band karne ka function
  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div className="target_industries">
      <h4>Target Industries</h4>

      <div className="target-industries-section">
        {candidate.targetIndustries && candidate.targetIndustries.length > 0 ? (
          <div className="exist-target-value">
            {!hideEditIcon && (
              <div className="editIcon" onClick={openModal}>
                <img src={editIcon} alt="Edit-icon" />
              </div>
            )}
            <div className="target-list-options">
              {candidate.targetIndustries.map((industry, index) => (
                <span key={index} className="industry-tag">
                  {industry}
                </span>
              ))}
            </div>
          </div>
        ) : (
          <div className="empty-state">
            <div className="plusIcon" onClick={openModal}>
              <img src={plusIcon} alt="plus-icon" />
            </div>
            <img src={settingIcon} alt="videoIcon" />
            <p>Let companies know the industries you're most interested in.</p>
            <button onClick={openModal}>Select Target Industries</button>
          </div>
        )}
      </div>

      <SelectTargetIndustries
        isOpen={isModalOpen}
        onClose={closeModal}
        candidate={candidate}
      />
    </div>
  );
}

export default TargetIndustries;
