import React, { useState, useContext } from "react";
import Modal from "react-modal";
import "./AddMilitary.css";
import { AuthContext } from "../../../../../context/AuthContext";
import { toast, ToastContainer } from "react-toastify";
import CompleteProfileMilitaryBackground from "../CompleteProfileMilitaryBackground";
Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "45%",
    marginRight: "-50%",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
    padding: "0px",
  },
};

function AddMilitary({ isOpen, onClose, candidate }) {
  return (
    <>
      <ToastContainer />
      <Modal
        isOpen={isOpen}
        style={customStyles}
        onRequestClose={onClose}
        contentLabel="Update Basic Information"
      >
        <button onClick={onClose} className="close-btn">
          X
        </button>
        {/* <div className="popup-content">
          <div className="popup-header">
            <h2>Edit Your Military Background</h2>
            <p>
              Provide details of your military service to give employers a
              complete view of your experience and qualifications.
            </p>
          </div>
          <hr />

          <div className="popup-form add-military">
            <div className="form-field">
              <label>
                Branch <span>*</span>
              </label>
              <select
                value={formData.branch}
                onChange={handleBranchChange}
                name="branch"
              >
                <option value="">Select Branch</option>
                {branches.map((loc, index) => (
                  <option key={index} value={loc}>
                    {loc}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-field">
              <label>
                MOS <span>*</span>
              </label>
              <input
                type="text"
                placeholder="Type Here"
                name="mos"
                value={formData.mos}
                onChange={handleChange}
              />
            </div>

            <div className="form-field">
              <label>
                Rank <span>*</span>
              </label>
              <select
                value={formData.rank}
                onChange={handleRankChange}
                name="rank"
              >
                <option value="">Select Rank</option>
                {ranks.map((st, index) => (
                  <option key={index} value={st}>
                    {st}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-field">
              <label>
                Years Served <span>*</span>
              </label>
              <input
                type="text"
                placeholder="Type Here"
                name="yearsServed"
                value={formData.yearsServed}
                onChange={handleChange}
              />
            </div>
          </div>

          <hr />

          <div className="form-btn">
            <button onClick={handleSubmit}>Save Changes</button>
          </div>
        </div> */}

        <CompleteProfileMilitaryBackground
          candidate={candidate}
          onClose={onClose}
        />
      </Modal>
    </>
  );
}

export default AddMilitary;
