import React, { useState } from "react";
import "./AboutMe.css";
import userIcon from "../../../../assets/imgs/userIcon.png";
import plusIcon from "../../../../assets/imgs/plusCircle.png";
import EditAboutProfileInfo from "./EditAbout/EditAboutProfileInfo";
import editIcon from "../../../../assets/imgs/Edit.png";

function AboutMe({ candidate, hideEditIcon }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Modal open karne ka function
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Modal band karne ka function
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const hasInfo =
    (candidate && candidate.idealJobTitle) ||
    candidate.securityClearance ||
    candidate.yearsOfExperience ||
    candidate.skillbridgeStartDate ||
    candidate.duringSkillbridgeCity ||
    candidate.duringSkillbridgeState ||
    candidate.afterskillbridgeCity ||
    candidate.afterskillbridgeState ||
    candidate.education ||
    candidate.relocationprefrence ||
    candidate.description;

  return (
    <div className="watch_my_intro">
      <h4>About Me</h4>

      <div>
        {!hasInfo ? (
          <div className="empty-state">
            <div className="plusIcon" onClick={openModal}>
              <img src={plusIcon} alt="plus-icon" />
            </div>
            <img src={userIcon} alt="userIcon" />
            <p>
              Help employers understand who you are and what you bring to the
              table. Write a short bio <br /> to showcase your strengths and
              goals.
            </p>
            <button onClick={openModal}>Add Information</button>
          </div>
        ) : (
          <div className="existing-info-about">
            {!hideEditIcon && (
              <div className="editIcon" onClick={openModal}>
                <img src={editIcon} alt="edit-icon" />
              </div>
            )}

            <p className="description">{candidate.description}</p>

            <div className="row-about-fields">
              <div className="left-column-box">
                <p>Ideal Job</p>
                <b>{candidate.idealJobTitle[0]}</b>
              </div>

              <div className="right-column-box">
                <p>Security Clearance</p>
                <b>{candidate.securityClearance}</b>
              </div>
            </div>
            <div className="row-about-fields">
              <div className="left-column-box">
                <p>Years of Experience</p>
                <b>{candidate.yearsOfExperience}</b>
              </div>

              {candidate.type !== "Veteran" && (
                <div className="right-column-box">
                  <p>Available Start</p>
                  <b>{candidate.skillbridgeStartDate}</b>
                </div>
              )}
            </div>

            {candidate.type !== "Veteran" && (
              <div className="row-about-fields">
                <div className="left-column-box">
                  <p>Location During Skillbridge</p>
                  {candidate.duringSkillbridgeCity &&
                    candidate.duringSkillbridgeState && (
                      <b>
                        {candidate.duringSkillbridgeCity},
                        {candidate.duringSkillbridgeState}{" "}
                      </b>
                    )}
                </div>

                <div className="right-column-box">
                  <p>Location After Skillbridge</p>
                  {candidate.afterskillbridgeCity &&
                    candidate.afterskillbridgeState && (
                      <b>
                        {candidate.afterskillbridgeCity},
                        {candidate.afterskillbridgeState}
                      </b>
                    )}
                </div>
              </div>
            )}

            <div className="row-about-fields">
              <div className="left-column-box">
                <p>Education</p>
                <b>{candidate.education}</b>
              </div>

              <div className="right-column-box">
                <p>Relocation Option</p>
                <b>{candidate.relocationprefrence}</b>
              </div>
            </div>
          </div>
        )}
      </div>

      <EditAboutProfileInfo
        isOpen={isModalOpen}
        onClose={closeModal}
        candidate={candidate}
      />
    </div>
  );
}

export default AboutMe;
