import React from "react";
import "./Candidate7EagleManager.css";
function Candidate7EagleManager({ rfCandidateData }) {
  return (
    <div className="manager_box">
      <h4>Your 7 Eagle Group Manager</h4>
      <div className="manager-profile">
        <img src={rfCandidateData.lead_owner.img_link} alt="manager-img" />

        <div>
          <h6 style={{ fontWeight: "bold" }}>
            {rfCandidateData.lead_owner.name}
          </h6>
          <h6 className="owner_email">{rfCandidateData.lead_owner.email}</h6>
          <h6>{rfCandidateData.lead_owner.phone}</h6>
        </div>
      </div>
      <p style={{ paddingTop: "15px", fontSize: "15px", textAlign: "center" }}>
        Can’t reach your Manager? <b>Contact Us</b>
      </p>
    </div>
  );
}

export default Candidate7EagleManager;
