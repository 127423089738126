import React, { useContext } from "react";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { AuthContext } from "../../../context/AuthContext"; // Import the AuthContext

function GoogleLoginButton({ setStep, onSuccess }) {
  const { login } = useContext(AuthContext); // Get the login function from AuthContext

  const handleSuccess = async (response) => {
    const token = response.credential; // Get the access token
    const decodedToken = jwtDecode(token); // Decode the token

    const userData = {
      email: decodedToken.email,
      given_name: decodedToken.given_name,
      family_name: decodedToken.family_name,
      picture: decodedToken.picture,
      phone: decodedToken.phone,
    };

    // Check if the user exists in your database
    const userExistsResponse = await fetch(
      `${process.env.REACT_APP_API_URL}/api/users/check-google-email`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: userData.email }),
      }
    );

    const userExistsData = await userExistsResponse.json();

    if (userExistsData.exists && userExistsData.googleRegistered) {
      // User is registered via Google, now login them
      const loginResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/api/users/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: userData.email,
            password: "Google Password", // Use a secure way to handle passwords
          }),
        }
      );

      const loginData = await loginResponse.json();
      if (loginData.token) {
        // Call the login function to set user and token in context
        login(loginData.token, loginData.user, false);
      } else {
        console.error("Failed to login");
      }
    } else if (!userExistsData.exists) {
      // Agar user nahi mila, to userData ke saath onSuccess ko call karo
      onSuccess(userData);
      setStep(5); // Move to the next step after successful registration
    } else {
      setStep(6);
    }
  };

  const onError = () => {
    console.log("Login Failed");
  };

  return (
    <GoogleOAuthProvider clientId="156451241066-pjp5j767i7e2t8pedrn3vbv3mjbvjplr.apps.googleusercontent.com">
      <div>
        <GoogleLogin
          onSuccess={handleSuccess}
          onError={onError}
          className="google-login-button"
        />
      </div>
    </GoogleOAuthProvider>
  );
}

export default GoogleLoginButton;
