import React, { useState } from "react";
import "./ProfileImageUpload.css";
import uploadImg from "../../../../assets/imgs/uploadIcon.png";
import fileImg from "../../../../assets/imgs/file.png";

function CompleteProfileImageUplaod({ candidate, onClose }) {
  const [image, setImage] = useState(null); // Image state rakhi
  const [uploading, setUploading] = useState(false); // Upload progress ke liye state
  const [progress, setProgress] = useState(0); // Progress bar ke liye
  const [error, setError] = useState(""); // Error handling ke liye
  const [toastShown, setToastShown] = useState(false); // Flag to track if toast is shown

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
      setError("");
      setImage(file); // Image file ko state mein store kar diya
    } else {
      setError(
        "You can’t upload this file. Only .jpg and .png formats are allowed."
      );
    }
  };

  const handleSaveChanges = () => {
    if (!image) {
      setError("Please select an image first.");
      return;
    }

    setUploading(true);
    const formData = new FormData();
    formData.append("headshotLink", image);

    const xhr = new XMLHttpRequest();
    xhr.open(
      "POST",
      `${process.env.REACT_APP_API_URL}/api/candidates/uploadImage/${candidate._id}`
    );

    // Track progress
    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentComplete = (event.loaded / event.total) * 100;
        setProgress(percentComplete);
      }
    };

    xhr.onload = () => {
      if (xhr.status === 200) {
        setProgress(100); // Set progress to 100% for UI update
        setTimeout(() => {
          setUploading(false); // Stop uploading state

          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }, 2000); // 1000 ms (1 second) delay before closing the modal
      } else {
        setError("Error uploading image");
        setUploading(false);
      }
    };

    xhr.onerror = () => {
      setError("Failed to upload image: Unknown error");
      setUploading(false);
    };

    xhr.send(formData);
  };
  return (
    <>
      <div className="popup-header">
        <h2>Upload or Edit Your Photo</h2>
        <p>
          Upload a clear, professional photo to help your profile stand out to
          hiring companies.
        </p>
      </div>
      <hr />
      <div className="upload-heading">
        <h6>Upload Your Photo</h6>
      </div>
      <div className="row" style={{ alignItems: "center" }}>
        <div className="col-lg-6">
          <div
            className="upload-box"
            onDragOver={(e) => e.preventDefault()}
            onDrop={(e) => {
              e.preventDefault();
              handleFileChange({
                target: { files: e.dataTransfer.files },
              });
            }}
          >
            {image ? (
              <div className="image-preview">
                <img src={URL.createObjectURL(image)} alt="Preview" />
              </div>
            ) : (
              <>
                <img src={uploadImg} alt="upload-img" className="uploadImg" />
                <p
                  style={{
                    padding: "10px",
                    fontWeight: "600",
                    fontSize: "16px",
                    paddingBottom: "0px",
                    margin: "0px",
                  }}
                >
                  Drag & drop files or{" "}
                  <label
                    htmlFor="file-upload"
                    className="browse-link"
                    style={{ cursor: "pointer " }}
                  >
                    <b> Browse</b>
                  </label>
                </p>
                <p style={{ fontSize: "12px" }}>
                  Supported formats: .jpg, .png
                </p>
              </>
            )}
            <input
              type="file"
              id="file-upload"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </div>
        </div>

        <div className="col-lg-6">
          <div className="left-content-popup">
            <h5 className="popup-left-heading">
              Show companies the best version of yourself!
            </h5>
            <p style={{ marginBottom: "0px" }}>
              Must be an actual photo of you. Logos, clip-art, group photos, and
              digitally-altered images are not allowed.
            </p>
          </div>
        </div>
      </div>

      {/* Progress bar */}
      {uploading && (
        <div className="progress-bar">
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div>
              <img src={fileImg} alt="fileImg" />
            </div>

            <div>
              <p style={{ marginBottom: "0px", paddingBottom: "0px" }}>
                Uploading...
              </p>

              <b style={{ marginBottom: "5px" }}>{image.name}</b>
            </div>
          </div>
          <div style={{ marginTop: "10px" }}>
            <div className="progress" style={{ width: `${progress}%` }}></div>
          </div>
        </div>
      )}
      {error && <p className="error-message">{error}</p>}

      <hr style={{ marginTop: "30px" }} />

      <div className="form-btn">
        <button onClick={handleSaveChanges}>Save Changes</button>
      </div>
    </>
  );
}

export default CompleteProfileImageUplaod;
