export const mosList = {
  enlisted: [
    "11B - Infantryman",
    "11C - Indirect Fire Infantryman (Mortarman)",
    "13B - Cannon Crewmember",
    "13F - Fire Support Specialist",
    "19D - Cavalry Scout",
    "19K - M1 Armor Crewman",
    "12B - Combat Engineer",
    "12C - Bridge Crewmember",
    "12K - Plumber",
    "12N - Horizontal Construction Engineer",
    "12R - Interior Electrician",
    "68W - Combat Medic Specialist",
    "68D - Operating Room Specialist",
    "68E - Dental Specialist",
    "68X - Behavioral Health Specialist",
    "15T - UH-60 Helicopter Repairer",
    "15Q - Air Traffic Control Operator",
    "15U - CH-47 Helicopter Repairer",
    "92Y - Unit Supply Specialist",
    "92A - Automated Logistical Specialist",
    "88M - Motor Transport Operator",
    "35F - Intelligence Analyst",
    "35G - Geospatial Intelligence Imagery Analyst",
    "35L - Counterintelligence Agent",
    "25B - Information Technology Specialist",
    "25S - Satellite Communication Systems Operator",
    "35M - Human Intelligence Collector",
    "35N - Signals Intelligence Analyst",
    "74D - Chemical, Biological, Radiological, and Nuclear (CBRN) Specialist",
    "18B - Special Forces Weapons Sergeant",
    "18C - Special Forces Engineer Sergeant",
    "18D - Special Forces Medical Sergeant",
    "18E - Special Forces Communications Sergeant",
    "31B - Military Police",
    "31K - Military Working Dog Handler",
    "68T - Animal Care Specialist",
    "91B - Wheeled Vehicle Mechanic",
    "91S - Stryker Systems Maintainer",
    "92F - Petroleum Supply Specialist",
    "92G - Culinary Specialist",
    "89D - Explosive Ordnance Disposal (EOD) Specialist",
    "0311 - Rifleman",
    "0331 - Machine Gunner",
    "0341 - Mortarman",
    "0351 - Infantry Assaultman",
    "0369 - Infantry Unit Leader",
    "0481 - Landing Support Specialist",
    "0621 - Field Radio Operator",
    "0313 - Light Armored Reconnaissance Marine",
    "6174 - Helicopter Crew Chief (CH-53)",
    "6326 - Aviation Mechanic (Avionics)",
    "7314 - Unmanned Aerial Vehicle Operator",
    "0231 - Intelligence Specialist",
    "0261 - Geographic Intelligence Specialist",
    "0211 - Counterintelligence/Human Source Intelligence Specialist",
    "0627 - Digital Wideband Systems Operator",
    "0651 - Cyber Network Operator",
    "5811 - Military Police",
    "5812 - Military Working Dog Handler",
    "5816 - Special Reaction Team Member",
    "3521 - Automotive Maintenance Technician",
    "3526 - Fuel and Electrical Systems Mechanic",
    "2111 - Small Arms Repairer/Technician",
    "2161 - Machinist",
    "BM - Boatswain’s Mate",
    "QM - Quartermaster",
    "ABF - Aviation Boatswain’s Mate (Fuel)",
    "EM - Electrician’s Mate",
    "MM - Machinist’s Mate",
    "EN - Engineman",
    "AT - Aviation Electronics Technician",
    "AW - Naval Aircrewman",
    "AE - Aviation Electrician’s Mate",
    "HM - Hospital Corpsman",
    "DT - Dental Technician",
    "IS - Intelligence Specialist",
    "CTI - Cryptologic Technician (Interpretive)",
    "CTN - Cryptologic Technician (Networks)",
    "CTR - Cryptologic Technician (Collection)",
    "IT - Information Systems Technician",
    "MA - Master-at-Arms",
    "GM - Gunner’s Mate",
    "FC - Fire Controlman",
    "SO - Special Warfare Operator (Navy SEAL)",
    "SB - Special Warfare Boat Operator",
    "ND - Navy Diver",
    "1A0X1 - In-Flight Refueling Specialist",
    "1C0X1 - Airfield Management",
    "1N0X1 - Intelligence Analyst",
    "2A3X3 - Tactical Aircraft Maintenance",
    "2M0X1 - Missile and Space Systems Maintenance",
    "2T2X1 - Air Transportation",
    "4N0X1 - Aerospace Medical Service",
    "4A0X1 - Health Services Management",
    "4C0X1 - Mental Health Service",
    "3P0X1 - Security Forces",
    "3D0X2 - Cyber Systems Operations",
    "3D1X1 - Client Systems Technician",
    "1T2X1 - Pararescue",
    "1W0X1 - Weather Specialist",
    "3E8X1 - Explosive Ordnance Disposal (EOD)",
    "BM - Boatswain’s Mate",
    "OS - Operations Specialist",
    "MK - Machinery Technician",
    "EM - Electrician’s Mate",
    "AMT - Aviation Maintenance Technician",
    "AST - Aviation Survival Technician",
    "YN - Yeoman",
    "SK - Storekeeper",
    "HS - Health Services Technician",
    "13S - Space Operations Officer",
    "1C6X1 - Space Systems Operations Specialist",
    "17D - Cyber Operations Officer",
    "1N8X1 - Targeting Analyst",
    "1B4X1 - Cyber Warfare Operations",
  ],
};
