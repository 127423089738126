// src/components/Login.js
import React, { useState, useContext } from "react"; // Import useContext

import { Link, useNavigate } from "react-router-dom"; // Import useNavigate for navigation

import "./Login.css"; // CSS file for styling
import Header from "../dashboard/Header/Header";
import loginLogo from "../../../assets/imgs/7eagle-logo.png";
import bgImg from "../../../assets/imgs/bg.png";

import userImg from "../../../assets/imgs/userblank.png";

import partnerIcon from "../../../assets/imgs/partnerIcon.png";
import "react-toastify/dist/ReactToastify.css";

const PreLogin = () => {
  const navigate = useNavigate(); // useNavigate hook for navigation
  const [isCompany, setIsCompany] = useState(true);
  const [isUser, setIsUser] = useState(false); // State for user checkbox

  const handleUserTypeChange = (type) => {
    if (type === "company") {
      setIsCompany(true);
      setIsUser(false);
    } else {
      setIsUser(true);
      setIsCompany(false);
    }
  };

  const handleCompanyLogin = () => {
    navigate("/company/login"); // Redirect to user registration page
  };

  const handleCandidateLogin = () => {
    navigate("/candidate/login"); // Redirect to user registration page
  };

  return (
    <div className="main-login">
      <Header />

      <div className="row" style={{ alignItems: "center" }}>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div className="login-banner">
            <img src={bgImg} alt="company-banner" />
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div>
            <div className="login-container">
              <>
                <img
                  src={loginLogo}
                  alt="company-logo"
                  className="company-logo"
                />
                <h2 className="user-type-heading-login">
                  Join as a Partner <br />
                  or Military Talent
                </h2>
              </>
              {/* Show user type selection */}

              <div className="user-type-selection">
                <div
                  className={`selection-box ${
                    isCompany ? "active-company" : ""
                  }`}
                >
                  <label>
                    <img src={partnerIcon} alt="partnericon" />
                    <input
                      type="radio"
                      checked={isCompany}
                      onChange={() => handleUserTypeChange("company")}
                    />
                  </label>
                  <p>I’m a partner company looking for top military talent</p>
                </div>

                <div className={`selection-box ${isUser ? "active-user" : ""}`}>
                  <label>
                    <img src={userImg} alt="userImg" />
                    <input
                      type="radio"
                      checked={isUser}
                      onChange={() => handleUserTypeChange("user")}
                    />
                  </label>
                  <p>I’m a military talent looking for great companies</p>
                </div>
              </div>

              {/* Show button for company registration */}
              {isCompany && (
                <div className="button-container">
                  <button
                    className="company-register-btn"
                    onClick={handleCompanyLogin}
                  >
                    Join as Partner Company
                  </button>

                  <p className="signup-link">Don’t have a 7 Eagle Account?</p>

                  <div className="signup-btn">
                    <Link to="/register">Sign Up</Link>
                  </div>
                </div>
              )}

              {isUser && (
                <div className="button-container">
                  <button
                    className="user-register-btn"
                    onClick={handleCandidateLogin}
                  >
                    Join as Talent
                  </button>

                  <p className="signup-link">Don’t have a 7 Eagle Account?</p>

                  <div className="signup-btn">
                    <Link to="/register">Sign Up</Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreLogin;
