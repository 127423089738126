import React, { useState } from "react";
import Modal from "react-modal"; // Modal ko import kiya
import "./UploadResume.css"; // Ensure to create a corresponding CSS file for styling
import uploadImg from "../../../../../assets/imgs/uploadIcon.png";
import fileImg from "../../../../../assets/imgs/file.png";
import { toast, ToastContainer } from "react-toastify";
Modal.setAppElement("#root"); // Accessibility ke liye zaroori hai

const UploadResume = ({ isOpen, onClose, candidate }) => {
  const [resume, setResume] = useState(null); // Resume state rakhi
  const [uploading, setUploading] = useState(false); // Upload progress ke liye state
  const [progress, setProgress] = useState(0); // Progress bar ke liye
  const [error, setError] = useState(""); // Error handling ke liye

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      width: "45%",
      marginRight: "-50%",
      position: "absolute",
      transform: "translate(-50%, -50%)",
      borderRadius: "10px",
      padding: "0px",
    },
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (
      file &&
      (file.type === "application/pdf" ||
        file.type === "application/msword" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document")
    ) {
      setError("");
      setResume(file); // Resume file ko state mein store kar diya
    } else {
      setError("You can only upload .pdf or .doc/.docx formats.");
    }
  };

  const handleSaveChanges = () => {
    if (!resume) {
      setError("Please select a resume first.");
      return;
    }

    setUploading(true);
    const formData = new FormData();
    formData.append("resume", resume);

    const xhr = new XMLHttpRequest();
    xhr.open(
      "POST",
      `${process.env.REACT_APP_API_URL}/api/candidates/uploadResume/${candidate._id}`
    );

    // Track progress
    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentComplete = (event.loaded / event.total) * 100;
        setProgress(percentComplete);
      }
    };

    xhr.onload = () => {
      if (xhr.status === 200) {
        setProgress(100); // Set progress to 100% for UI update
        setTimeout(() => {
          setUploading(false); // Stop uploading state
          onClose(); // Close the modal after a delay
        }, 2000); // 1000 ms (1 second) delay before closing the modal
        toast.success("Candidate updated successfully!");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        setError("Error uploading resume");
        setUploading(false);
      }
    };

    xhr.onerror = () => {
      toast.error("Error updating candidate: " + error.message);
      setError("Failed to upload resume: Unknown error");
      setUploading(false);
    };

    xhr.send(formData);
  };

  return (
    <div>
      <ToastContainer />
      <Modal
        isOpen={isOpen}
        contentLabel="Upload Resume"
        onRequestClose={onClose}
        style={customStyles}
      >
        <div className="popup-content">
          <button onClick={onClose} className="close-btn">
            X
          </button>

          <div className="popup-header">
            <h2>Upload or Edit Your Resume</h2>
            <p>
              Upload a clear, professional resume to showcase yourself to hiring
              companies.
            </p>
          </div>
          <hr />
          <div className="upload-heading">
            <h6>Upload Your Resume</h6>
          </div>
          <div className="row" style={{ alignItems: "center" }}>
            <div className="col-lg-6">
              <div
                className="upload-box"
                onDragOver={(e) => e.preventDefault()}
                onDrop={(e) => {
                  e.preventDefault();
                  handleFileChange({
                    target: { files: e.dataTransfer.files },
                  });
                }}
              >
                {resume ? (
                  <div className="resume-preview">
                    <iframe
                      src={URL.createObjectURL(resume)}
                      style={{ width: "100%" }}
                    />
                  </div>
                ) : (
                  <>
                    <img
                      src={uploadImg}
                      alt="upload-img"
                      className="uploadImg"
                    />
                    <p
                      style={{
                        padding: "10px",
                        fontWeight: "600",
                        fontSize: "16px",
                        paddingBottom: "0px",
                        margin: "0px",
                      }}
                    >
                      Drag & drop files or{" "}
                      <label
                        htmlFor="file-upload"
                        className="browse-link"
                        style={{ cursor: "pointer " }}
                      >
                        <b> Browse</b>
                      </label>
                    </p>
                    <p style={{ fontSize: "12px" }}>
                      Supported formats: .pdf, .doc, .docx
                    </p>
                  </>
                )}
                <input
                  type="file"
                  id="file-upload"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
              </div>
            </div>

            <div className="col-lg-6">
              <div className="left-content-popup">
                <h5 className="popup-left-heading">
                  Show companies the best version of yourself!
                </h5>
                <p style={{ marginBottom: "0px" }}>
                  Must be an actual resume of you. Generic templates and
                  irrelevant content are not allowed.
                </p>
              </div>
            </div>
          </div>

          {/* Progress bar */}
          {uploading && (
            <div className="progress-bar">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div>
                  <img src={fileImg} alt="fileImg" />
                </div>

                <div>
                  <p style={{ marginBottom: "0px", paddingBottom: "0px" }}>
                    Uploading...
                  </p>

                  <b style={{ marginBottom: "5px" }}>{resume.name}</b>
                </div>
              </div>
              <div style={{ marginTop: "10px" }}>
                <div
                  className="progress"
                  style={{ width: `${progress}%` }}
                ></div>
              </div>
            </div>
          )}
          {error && <p className="error-message">{error}</p>}

          <hr style={{ marginTop: "30px" }} />

          <div className="form-btn">
            <button onClick={handleSaveChanges}>Save Changes</button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default UploadResume;
