export const statesWithCities = {
  Alabama: [
    "Birmingham",
    "Montgomery",
    "Mobile",
    "Huntsville",
    "Tuscaloosa",
    "Hoover",
    "Dothan",
    "Auburn",
    "Decatur",
    "Florence",
  ],
  Alaska: [
    "Anchorage",
    "Fairbanks",
    "Juneau",
    "Sitka",
    "Wasilla",
    "Kodiak",
    "Bethel",
    "Kenai",
    "Palmer",
    "Nome",
  ],
  Arkansas: [
    "Little Rock",
    "Fayetteville",
    "Fort Smith",
    "Springdale",
    "Jonesboro",
    "North Little Rock",
    "Conway",
    "Rogers",
    "Pine Bluff",
    "Benton",
  ],
  California: [
    "Los Angeles",
    "San Francisco",
    "San Diego",
    "Sacramento",
    "San Jose",
    "Fresno",
    "Long Beach",
    "Oakland",
    "Bakersfield",
    "Anaheim",
  ],
  Colorado: [
    "Denver",
    "Colorado Springs",
    "Aurora",
    "Fort Collins",
    "Boulder",
    "Greeley",
    "Thornton",
    "Arvada",
    "Westminster",
    "Pueblo",
  ],
  Connecticut: [
    "Hartford",
    "New Haven",
    "Stamford",
    "Bridgeport",
    "Waterbury",
    "Norwalk",
    "Danbury",
    "New Britain",
    "West Hartford",
    "Bristol",
  ],
  Delaware: [
    "Wilmington",
    "Dover",
    "Newark",
    "Middletown",
    "Milford",
    "Smyrna",
    "Georgetown",
    "Clayton",
    "Bear",
    "Elsmere",
  ],
  Florida: [
    "Miami",
    "Orlando",
    "Tampa",
    "Jacksonville",
    "St. Petersburg",
    "Hialeah",
    "Tallahassee",
    "Fort Lauderdale",
    "Cape Coral",
    "Pembroke Pines",
  ],
  Georgia: [
    "Atlanta",
    "Savannah",
    "Augusta",
    "Columbus",
    "Macon",
    "Marietta",
    "Albany",
    "Valdosta",
    "Smyrna",
    "Johns Creek",
  ],
  Hawaii: [
    "Honolulu",
    "Hilo",
    "Kailua",
    "Kaneohe",
    "Pearl City",
    "Waipahu",
    "Makakilo",
    "Mililani Mauka",
    "Kihei",
    "Lahaina",
  ],
  Idaho: [
    "Boise",
    "Meridian",
    "Nampa",
    "Idaho Falls",
    "Pocatello",
    "Caldwell",
    "Coeur d'Alene",
    "Lewiston",
    "Twin Falls",
    "Rexburg",
  ],
  Illinois: [
    "Chicago",
    "Springfield",
    "Aurora",
    "Naperville",
    "Rockford",
    "Joliet",
    "Elgin",
    "Waukegan",
    "Champaign",
    "Bloomington",
  ],
  Indiana: [
    "Indianapolis",
    "Fort Wayne",
    "Evansville",
    "South Bend",
    "Carmel",
    "Fishers",
    "Bloomington",
    "Lafayette",
    "Muncie",
    "Terre Haute",
  ],
  Iowa: [
    "Des Moines",
    "Cedar Rapids",
    "Davenport",
    "Sioux City",
    "Iowa City",
    "Waterloo",
    "Council Bluffs",
    "Ames",
    "West Des Moines",
    "Urbandale",
  ],
  Kansas: [
    "Wichita",
    "Overland Park",
    "Kansas City",
    "Topeka",
    "Olathe",
    "Lawrence",
    "Shawnee",
    "Manhattan",
    "Lenexa",
    "Hutchinson",
  ],
  Kentucky: [
    "Louisville",
    "Lexington",
    "Bowling Green",
    "Owensboro",
    "Covington",
    "Richmond",
    "Florence",
    "Nicholasville",
    "Georgetown",
    "Paducah",
  ],
  Louisiana: [
    "New Orleans",
    "Baton Rouge",
    "Shreveport",
    "Lafayette",
    "Lake Charles",
    "Kenner",
    "Bossier City",
    "Monroe",
    "Alexandria",
    "Hammond",
  ],
  Maine: [
    "Portland",
    "Lewiston",
    "Bangor",
    "South Portland",
    "Auburn",
    "Biddeford",
    "Saco",
    "Augusta",
    "Westbrook",
    "Scarborough",
  ],
  Maryland: [
    "Baltimore",
    "Frederick",
    "Rockville",
    "Gaithersburg",
    "Bowie",
    "Hagerstown",
    "Annapolis",
    "Laurel",
    "Hyattsville",
    "Salisbury",
  ],
  Massachusetts: [
    "Boston",
    "Worcester",
    "Springfield",
    "Cambridge",
    "Lowell",
    "Brockton",
    "Quincy",
    "Lynn",
    "Newton",
    "Somerville",
  ],
  Michigan: [
    "Detroit",
    "Grand Rapids",
    "Warren",
    "Sterling Heights",
    "Ann Arbor",
    "Lansing",
    "Flint",
    "Dearborn",
    "Livonia",
    "Westland",
  ],
  Minnesota: [
    "Minneapolis",
    "Saint Paul",
    "Rochester",
    "Duluth",
    "Bloomington",
    "Brooklyn Park",
    "Plymouth",
    "Maple Grove",
    "Coon Rapids",
    "Burnsville",
  ],
  Mississippi: [
    "Jackson",
    "Gulfport",
    "Southaven",
    "Hattiesburg",
    "Biloxi",
    "Meridian",
    "Tupelo",
    "Olive Branch",
    "Pascagoula",
    "Starkville",
  ],
  Missouri: [
    "Kansas City",
    "St. Louis",
    "Springfield",
    "Columbia",
    "Lee's Summit",
    "O'Fallon",
    "St. Joseph",
    "St. Charles",
    "Independence",
    "Blue Springs",
  ],
  Montana: [
    "Billings",
    "Missoula",
    "Great Falls",
    "Bozeman",
    "Butte",
    "Helena",
    "Kalispell",
    "Havre",
    "Anaconda",
    "Miles City",
  ],
  Nebraska: [
    "Omaha",
    "Lincoln",
    "Bellevue",
    "Grand Island",
    "Kearney",
    "Fremont",
    "Hastings",
    "North Platte",
    "Columbus",
    "Papillion",
  ],
  Nevada: [
    "Las Vegas",
    "Henderson",
    "Reno",
    "North Las Vegas",
    "Sparks",
    "Carson City",
    "Elko",
    "Boulder City",
    "Mesquite",
    "Fallon",
  ],
  NewHampshire: [
    "Manchester",
    "Nashua",
    "Concord",
    "Derry",
    "Rochester",
    "Salem",
    "Hudson",
    "Keene",
    "Laconia",
    "Merrimack",
  ],
  NewJersey: [
    "Newark",
    "Jersey City",
    "Paterson",
    "Elizabeth",
    "Edison",
    "Toms River",
    "Clifton",
    "Camden",
    "Bayonne",
    "Trenton",
  ],
  NewMexico: [
    "Albuquerque",
    "Las Cruces",
    "Rio Rancho",
    "Santa Fe",
    "Roswell",
    "Carlsbad",
    "Hobbs",
    "Alamogordo",
    "Clovis",
    "Deming",
  ],
  NewYork: [
    "New York City",
    "Buffalo",
    "Rochester",
    "Yonkers",
    "Syracuse",
    "Albany",
    "New Rochelle",
    "Mount Vernon",
    "Schenectady",
    "Utica",
  ],
  NorthCarolina: [
    "Charlotte",
    "Raleigh",
    "Greensboro",
    "Durham",
    "Winston-Salem",
    "Fayetteville",
    "Cary",
    "Wilmington",
    "High Point",
    "Asheville",
  ],
  Ohio: [
    "Columbus",
    "Cleveland",
    "Cincinnati",
    "Toledo",
    "Akron",
    "Dayton",
    "Parma",
    "Canton",
    "Lorain",
    "Hamilton",
  ],
  Oklahoma: [
    "Oklahoma City",
    "Tulsa",
    "Norman",
    "Broken Arrow",
    "Edmond",
    "Lawton",
    "Moore",
    "Stillwater",
    "Enid",
    "Midwest City",
  ],
  Oregon: [
    "Portland",
    "Salem",
    "Eugene",
    "Gresham",
    "Hillsboro",
    "Beaverton",
    "Bend",
    "Medford",
    "Springfield",
    "Corvallis",
  ],
  Pennsylvania: [
    "Philadelphia",
    "Pittsburgh",
    "Allentown",
    "Erie",
    "Reading",
    "Scranton",
    "Bethlehem",
    "Lancaster",
    "Harrisburg",
    "York",
    "Altoona",
  ],
  RhodeIsland: [
    "Providence",
    "Warwick",
    "Cranston",
    "Pawtucket",
    "East Providence",
    "Woonsocket",
    "Newport",
    "South Kingstown",
    "Cumberland",
    "North Kingstown",
  ],
  SouthCarolina: [
    "Charleston",
    "Columbia",
    "North Charleston",
    "Greenville",
    "Spartanburg",
    "Summerville",
    "Hilton Head Island",
    "Florence",
    "Rock Hill",
    "Myrtle Beach",
  ],
  SouthDakota: [
    "Sioux Falls",
    "Rapid City",
    "Aberdeen",
    "Brookings",
    "Mitchell",
    "Yankton",
    "Huron",
    "Pierre",
    "Vermillion",
    "Deadwood",
  ],
  Tennessee: [
    "Nashville",
    "Memphis",
    "Knoxville",
    "Chattanooga",
    "Murfreesboro",
    "Jackson",
    "Johnson City",
    "Smyrna",
    "Franklin",
    "Hendersonville",
  ],
  Texas: [
    "Houston",
    "San Antonio",
    "Dallas",
    "Austin",
    "Fort Worth",
    "El Paso",
    "Arlington",
    "Corpus Christi",
    "Plano",
    "Laredo",
  ],
  Utah: [
    "Salt Lake City",
    "West Valley City",
    "Provo",
    "Sandy",
    "Orem",
    "Layton",
    "South Jordan",
    "Logan",
    "Millcreek",
    "Riverton",
  ],
  Vermont: [
    "Burlington",
    "South Burlington",
    "Rutland",
    "Montpelier",
    "Winooski",
    "Stowe",
    "Newport",
    "Essex Junction",
    "Milton",
    "Springfield",
  ],
  Virginia: [
    "Virginia Beach",
    "Norfolk",
    "Chesapeake",
    "Richmond",
    "Newport News",
    "Alexandria",
    "Hampton",
    "Roanoke",
    "Lynchburg",
    "Harrisonburg",
  ],
  Washington: [
    "Seattle",
    "Spokane",
    "Tacoma",
    "Vancouver",
    "Bellevue",
    "Kent",
    "Everett",
    "Renton",
    "Bellingham",
    "Yakima",
  ],
  WestVirginia: [
    "Charleston",
    "Huntington",
    "Morgantown",
    "Parkersburg",
    "Wheeling",
    "Weirton",
    "Fairmont",
    "Beckley",
    "Clarksburg",
    "Martinsburg",
  ],
  Wisconsin: [
    "Milwaukee",
    "Madison",
    "Green Bay",
    "Kenosha",
    "Racine",
    "Appleton",
    "Waukesha",
    "Oshkosh",
    "Eau Claire",
    "Janesville",
  ],
  Wyoming: [
    "Cheyenne",
    "Casper",
    "Laramie",
    "Gillette",
    "Rock Springs",
    "Sheridan",
    "Johnson City",
    "Evanston",
    "Riverton",
    "Cody",
  ],
};
