import React, { useState, useContext } from "react";
import Modal from "react-modal"; // Modal library import
import "./EditAboutProfileInfo.css";
import { toast } from "react-toastify"; // For displaying success/error messages
import { statesWithCities } from "./LocationList";
import { AuthContext } from "../../../../../context/AuthContext";
import { IdealJobList } from "./IdealJobList";
Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "45%",
    marginRight: "-50%",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
    padding: "0px",
  },
};

function EditAboutProfileInfo({ isOpen, onClose, candidate }) {
  const { user } = useContext(AuthContext);
  const [currentIdealJob, setCurrentIdealJob] = useState(
    candidate.idealJobTitle || ""
  );

  const [duringquestion, setDuringQuetion] = useState();
  const [inputValue, setInputValue] = useState("");
  const [currentSuggestions, setCurrentSuggestions] = useState([]);
  const [formData, setFormData] = useState({
    fname: candidate.fname || "",
    lname: candidate.lname || "",
    candidateType: candidate.type || "",
    state: candidate.state || "",
    city: candidate.city || "",

    description: candidate.description || "",
    securityClearance: candidate.securityClearance || "",
    yearsOfExperience: candidate.yearsOfExperience || "",
    skillbridgeStartDate: candidate.skillbridgeStartDate || "",
    skillbridgeEndDate: candidate.skillbridgeEndDate || "",
    idealJobTitle: candidate.idealJobTitle || "",
    duringSkillbridgeCity: candidate.duringSkillbridgeCity || "",
    duringSkillbridgeState: candidate.duringSkillbridgeState || "",
    afterskillbridgeCity: candidate.afterskillbridgeCity || "",
    afterskillbridgeState: candidate.afterskillbridgeState || "",
    relocationprefrence: candidate.relocationprefrence || "",
    education: candidate.education || "",
    idno: candidate.recruiterFlowId || "", // Yeh ID RecruiterFlow ki hai
    email: candidate.email || "",
    phone: candidate.phone || "",
    certifications: candidate.certifications || "",
    Pursuingcertifications: candidate.Pursuingcertifications || "",
    branch: candidate.branch || "", // Optional chaining
    rank: candidate.rank || "",
    mos: candidate.mos || "",
    targetIndustries: candidate.targetIndustries || "",
    yearsServed: candidate.yearsServed || "",
    locationDuringSkillbridge: candidate.locationDuringSkillbridge || "",
    locationAfterSkillbridge: candidate.locationAfterSkillbridge || "",
  });

  const addCurrentJobTitle = (certification) => {
    if (!currentIdealJob.includes(certification)) {
      setCurrentIdealJob((prev) => [...prev, certification]);
    }
  };

  // Handle input for current certifications
  const handleCurrentInput = (e) => {
    const input = e.target.value;
    setInputValue(input);

    if (input.length > 0) {
      const filteredSuggestions = IdealJobList.filter((cert) =>
        cert.toLowerCase().includes(input.toLowerCase())
      );

      // Add "Create new:" suggestion if not in the list
      if (!filteredSuggestions.includes(input)) {
        filteredSuggestions.push(`Create new: ${input}`);
      }

      setCurrentSuggestions(filteredSuggestions);
    } else {
      setCurrentSuggestions([]);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && inputValue.trim() !== "") {
      addCurrentJobTitle(inputValue.trim());
      setInputValue("");
      setCurrentSuggestions([]);
    }
  };

  // Handle suggestion click for current certifications
  const handleCurrentSuggestionClick = (certification) => {
    if (certification.startsWith("Create new: ")) {
      const newCertification = certification.replace("Create new: ", "");
      addCurrentJobTitle(newCertification);
    } else {
      addCurrentJobTitle(certification);
    }
    setInputValue("");
    setCurrentSuggestions([]);
  };

  // Remove current certification
  const handleRemoveCert = (index) => {
    const updatedJobTitle = currentIdealJob.filter((_, i) => i !== index);
    setCurrentIdealJob(updatedJobTitle);
  };

  const handleStateChange = (e) => {
    const selectedState = e.target.value;
    setFormData((prevData) => ({
      ...prevData,
      duringSkillbridgeState: selectedState,
      duringSkillbridgeCity: "", // Reset city when state is changed
    }));
  };

  const handleDuringSkillbridgeQuestion = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setDuringQuetion(e.target);
    console.log(duringquestion);
  };

  const handleAfterSkillbridgeQuestion = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleCityChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      duringSkillbridgeCity: e.target.value,
    }));
  };

  const handleAfterStateChange = (e) => {
    const selectedState = e.target.value;
    setFormData((prevData) => ({
      ...prevData,
      afterskillbridgeState: selectedState,
      afterskillbridgeCity: "", // Reset city when state is changed
    }));
  };

  const handleAfterCityChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      afterskillbridgeCity: e.target.value,
    }));
  };

  const securityClearances = [
    "Confidential",
    "Secret",
    "Top Secret",
    "Top Secret with Full Scope Poly",
    "Top Secret with CI Poly",
    "Don't have a clearance",
  ];
  const yearOfExperiences = [
    "Zero - I'm making a career pivot.",
    "Minimal - I have less than 2 years of experience.",
    "Mid - I have 3-7 years of experience.",
    "Senior - I have 7+ years of experience.",
  ];

  const skillbridgeQuestions = [
    "Yes - I know exactly where I'll live.",
    "Almost - I have a few options I'm considering.",
    "No - but I'm willing to relocate for the job.",
  ];
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        `http://localhost:5000/api/candidates/${candidate._id}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${user.token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...formData,
            idealJobTitle: currentIdealJob,
          }), // Pass the complete form data
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update candidate");
      }

      const updatedCandidate = await response.json();
      toast.success("Candidate updated successfully!");
      onClose();
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      toast.error("Error updating candidate: " + error.message);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      onRequestClose={onClose}
      contentLabel="Update Basic Information"
    >
      <button onClick={onClose} className="close-btn">
        X
      </button>
      <div className="popup-content">
        <div className="popup-header">
          <h2>Edit Your Profile Information</h2>
          <p>
            Give employers a better sense of who you are and what you're looking
            for. Highlight your background, ideal job, and availability to stand
            out!
          </p>
        </div>
        <hr />

        <div className="popup-form about-form">
          <div className="form-field">
            <label>
              Tell companies about yourself and your career goals.
              <span>*</span>
            </label>
            <textarea
              placeholder="Type Here"
              name="description"
              value={formData.description}
              onChange={handleChange}
            />
          </div>

          <div className="form-field">
            <>
              <label>
                Specify the type of role you’re aiming for <span>*</span>
              </label>

              <input
                type="text"
                value={inputValue}
                onChange={handleCurrentInput}
                onKeyDown={handleKeyDown}
                placeholder="Type or search your certifications here"
              />
              <p style={{ fontSize: "12px", paddingTop: "10px" }}>
                Please type the exact job title you are looking for (e.g.,
                Software Engineer, Marketing Manager, Sales Representative). Be
                as specific as possible to help us match you with the right
                opportunities.
              </p>
              {currentSuggestions.length > 0 && (
                <ul className="suggestions">
                  {currentSuggestions.map((suggestion, index) => (
                    <li
                      key={index}
                      onClick={() => handleCurrentSuggestionClick(suggestion)}
                      className="suggestion-item"
                    >
                      {suggestion}
                    </li>
                  ))}
                </ul>
              )}

              <div className="certifications-tags">
                {currentIdealJob.map((cert, index) => (
                  <span
                    key={index}
                    className="tag"
                    style={{ marginBottom: "10px" }}
                  >
                    {cert}{" "}
                    <button onClick={() => handleRemoveCert(index)}>x</button>
                  </span>
                ))}
              </div>
            </>
          </div>

          {/* Security Clearance - Years of Experience */}
          <div className="two-fields">
            <div className="form-field">
              <label>
                Security Clearance <span>*</span>
              </label>
              <select
                value={formData.securityClearance}
                onChange={handleChange}
                name="securityClearance"
              >
                <option value="">Select Option</option>
                {securityClearances.map((loc, index) => (
                  <option key={index} value={loc}>
                    {loc}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-field">
              <label>
                Years of Experience <span>*</span>
              </label>
              <select
                value={formData.yearsOfExperience}
                onChange={handleChange}
                name="yearsOfExperience"
              >
                <option value="">Select Option</option>
                {yearOfExperiences.map((exp, index) => (
                  <option key={index} value={exp}>
                    {exp}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* Check if candidate type is not 'Veteran' */}
          {candidate.type !== "Veteran" && (
            <>
              {/* SkillBridge Start Date - Skillbridge End date */}
              <div className="two-fields">
                <div className="form-field">
                  <label>
                    Skillbridge Start Date <span>*</span>
                  </label>
                  <input
                    type="date"
                    name="skillbridgeStartDate"
                    value={formData.skillbridgeStartDate}
                    onChange={handleChange}
                  />
                </div>

                <div className="form-field">
                  <label>
                    Skillbridge End Date <span>*</span>
                  </label>
                  <input
                    type="date"
                    name="skillbridgeEndDate"
                    value={formData.skillbridgeEndDate}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="form-field">
                <label>
                  Do you know where you will live DURING SkillBridge? *{" "}
                  <span>*</span>
                </label>
                <select
                  value={formData.locationDuringSkillbridge}
                  onChange={handleDuringSkillbridgeQuestion}
                  name="locationDuringSkillbridge"
                >
                  <option value="">Select Option</option>
                  {skillbridgeQuestions.map((exp, index) => (
                    <option key={index} value={exp}>
                      {exp}
                    </option>
                  ))}
                </select>
              </div>
              {(formData.locationDuringSkillbridge ===
                "Yes - I know exactly where I'll live." ||
                formData.locationDuringSkillbridge ===
                  "Almost - I have a few options I'm considering.") && (
                <div className="duringskillbridgecondition">
                  {/* During SkillBridge */}
                  <div className="form-field">
                    <label>
                      Location DURING Skillbridge <span>*</span>
                    </label>
                  </div>

                  {/* Location During Skillbridge */}
                  <div className="two-fields">
                    <div className="form-field">
                      <select
                        name="duringSkillbridgeState"
                        value={formData.duringSkillbridgeState}
                        onChange={handleStateChange}
                      >
                        <option value="">Select State</option>
                        {Object.keys(statesWithCities).map((state) => (
                          <option key={state} value={state}>
                            {state}
                          </option>
                        ))}
                      </select>
                      <span className="sub-span">State</span>
                    </div>
                    <div className="form-field">
                      <select
                        name="duringSkillbridgeCity"
                        value={formData.duringSkillbridgeCity}
                        onChange={handleCityChange}
                        disabled={!formData.duringSkillbridgeState} // Disable until state is selected
                      >
                        <option value="">Select City</option>
                        {formData.duringSkillbridgeState &&
                          statesWithCities[formData.duringSkillbridgeState].map(
                            (city) => (
                              <option key={city} value={city}>
                                {city}
                              </option>
                            )
                          )}
                      </select>
                      <span className="sub-span">City</span>
                    </div>
                  </div>
                </div>
              )}

              <div className="form-field">
                <label>
                  Do you know where you will live After SkillBridge?{" "}
                  <span>*</span>
                </label>
                <select
                  value={formData.locationAfterSkillbridge}
                  onChange={handleAfterSkillbridgeQuestion}
                  name="locationAfterSkillbridge"
                >
                  <option value="">Select Option</option>
                  {skillbridgeQuestions.map((exp, index) => (
                    <option key={index} value={exp}>
                      {exp}
                    </option>
                  ))}
                </select>
              </div>

              {(formData.locationAfterSkillbridge ===
                "Yes - I know exactly where I'll live." ||
                formData.locationAfterSkillbridge ===
                  "Almost - I have a few options I'm considering.") && (
                <div className="condition-afterskillbridge">
                  {/* After SkillBridge */}
                  <div className="form-field">
                    <label>
                      Location After Skillbrdige <span>*</span>
                    </label>
                  </div>

                  {/* Location After Skillbridge */}
                  <div className="two-fields">
                    <div className="form-field">
                      <select
                        name="afterskillbridgeState"
                        value={formData.afterskillbridgeState}
                        onChange={handleAfterStateChange}
                      >
                        <option value="">Select State</option>
                        {Object.keys(statesWithCities).map((state) => (
                          <option key={state} value={state}>
                            {state}
                          </option>
                        ))}
                      </select>
                      <span className="sub-span">State</span>
                    </div>
                    <div className="form-field">
                      <select
                        name="afterskillbridgeCity"
                        value={formData.afterskillbridgeCity}
                        onChange={handleAfterCityChange}
                        disabled={!formData.afterskillbridgeState} // Disable until state is selected
                      >
                        <option value="">Select City</option>
                        {formData.afterskillbridgeState &&
                          statesWithCities[formData.afterskillbridgeState].map(
                            (city) => (
                              <option key={city} value={city}>
                                {city}
                              </option>
                            )
                          )}
                      </select>
                      <span className="sub-span">City</span>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}

          {/* Relocation Preference */}
          <div className="form-field">
            <label>
              Are you open to relocating? <span>*</span>
            </label>
            <select
              name="relocationprefrence"
              value={formData.relocationprefrence}
              onChange={handleChange}
            >
              <option value="">Select Option</option>
              <option value="I can live practically anywhere…my bag is already packed!">
                I can live practically anywhere…my bag is already packed!
              </option>
              <option
                value=" I would strongly consider relocating for the right SkillBridge
                Opportunity."
              >
                I would strongly consider relocating for the right SkillBridge
                Opportunity.
              </option>
              <option value=" It's unlikely unless it's an amazing offer.">
                It's unlikely unless it's an amazing offer.
              </option>
              <option value="It's impossible to relocate…I must live in a certain location.">
                It's impossible to relocate…I must live in a certain location.
              </option>
            </select>
          </div>

          {/* Education */}
          <div className="form-field">
            <label>
              Education <span>*</span>
            </label>
            <input
              type="text"
              placeholder="Type Here"
              name="education"
              value={formData.education}
              onChange={handleChange}
            />
          </div>
        </div>

        <hr />
        <div className="form-btn">
          <button type="submit" className="update-btn" onClick={handleSubmit}>
            Update
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default EditAboutProfileInfo;
