import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import Header from "../../components/candidate/dashboard/Header/Header";
import Sidebar from "../../components/candidate/dashboard/Sidebar/Sidebar";
import "../company/Dashboard.css";
import { useNavigate } from "react-router-dom";
import ProfileWarning from "../../components/candidate/dashboard/Profile Warning/ProfileWarning";
import CandidateProfileBox from "../../components/candidate/dashboard/Profile/CandidateProfileBox";
import CandidateAccountProgress from "../../components/candidate/dashboard/Account/CandidateAccountProgress";
import WatchMyIntroVideo from "../../components/candidate/dashboard/WatchMyIntro/WatchMyIntroVideo";
import AboutMe from "../../components/candidate/dashboard/About Me/AboutMe";
import Certification from "../../components/candidate/dashboard/Certifications/Certification";
import TargetIndustries from "../../components/candidate/dashboard/Target Industries/TargetIndustries";
import MilitaryBackground from "../../components/candidate/dashboard/Military Background/MilitaryBackground";
import BasicInformation from "../../components/candidate/dashboard/Basic Information/BasicInformation";
import Resume from "../../components/candidate/dashboard/Resume/Resume";
import logo from "../../assets/imgs/logo-7eagle.png";
import axios from "axios";
import "./CandidateDashboard.css";
import JobSearchStatus from "../../components/candidate/dashboard/Job Search Status/JobSearchStatus";
import Candidate7EagleManager from "../../components/candidate/dashboard/Candidate 7EagleManager/Candidate7EagleManager";

const CandidateDashboard = () => {
  const { isAuthenticated, user, token, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [candidate, setCandidate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [rfCandidateData, setRfCandidateData] = useState(null);

  const handleToggleSidebar = (isCollapsed) => {
    setIsSidebarCollapsed(isCollapsed);
  };

  useEffect(() => {
    const fetchCandidate = async () => {
      if (!isAuthenticated || !user || !token) {
        navigate("/candidate/login");
        return;
      }

      try {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const userId = user.id;
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/candidates/user/${userId}`,
          config
        );

        setCandidate(response.data.data);
      } catch (error) {
        console.error(
          "Candidate data fetch karne mein ghalti:",
          error.response ? error.response.data : error.message
        );
      }
    };

    fetchCandidate();
  }, [isAuthenticated, user, token, navigate]);

  useEffect(() => {
    const fetchRfCandidateData = async () => {
      if (!candidate) return; // Make sure candidate is fetched

      try {
        const response = await axios.get(
          `http://localhost:5000/api/candidates/rfCandidate/${candidate.recruiterFlowId}` // Use candidate ID here
        );
        setRfCandidateData(response.data);
      } catch (err) {
        console.error("Error fetching RFCandidate data:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchRfCandidateData();
  }, [candidate]);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/candidate/login");
    }
  }, [isAuthenticated, navigate]);

  if (loading) {
    return (
      <div className="loading-container">
        <img src={logo} className="logo-pre-loader" />
        <div className="spinner"></div>

        <div>
          <h6>Please Wait..</h6>
        </div>
      </div>
    );
  }

  // Check candidate after loading is done
  if (!loading && !candidate) {
    logout(); // Log out the user
    navigate("/candidate/login"); // Redirect to login
  }

  return (
    <div className="dashboard">
      <Header candidate={candidate} />
      <div className="row dashboardrow" style={{ minHeight: "100vh" }}>
        <div
          className={`col-lg-2 col-md-2 col-sm-4 col-4 sidebar ${
            isSidebarCollapsed ? "sidebarCollapsed" : ""
          }`}
        >
          <Sidebar
            onToggleSidebar={handleToggleSidebar}
            candidate={candidate}
          />
        </div>
        <div
          className={`col-lg-10 col-md-10 col-sm-8 col-8 sidebar-column contentbar ${
            isSidebarCollapsed ? "contentExpanded" : ""
          }`}
        >
          <main>
            <div className="welcome-text">
              <h6>
                Welcome back to your dashboard,
                <span> {candidate?.name || ""}</span> {/* Optional chaining */}
              </h6>
            </div>

            <ProfileWarning candidate={candidate} />
            <CandidateProfileBox candidate={candidate} />
            <div className="row">
              <div className="col-lg-3 col-md-3 col-12">
                <JobSearchStatus candidate={candidate} />
                <CandidateAccountProgress candidate={candidate} />
                <BasicInformation candidate={candidate} />
                <Resume candidate={candidate} />
                <Candidate7EagleManager rfCandidateData={rfCandidateData} />
              </div>
              <div className="col-lg-9 col-md-9 col-12">
                <WatchMyIntroVideo candidate={candidate} user={user} />
                <AboutMe candidate={candidate} />
                <Certification candidate={candidate} />
                <TargetIndustries candidate={candidate} />
                <MilitaryBackground candidate={candidate} />
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default CandidateDashboard;
