import React, { useState } from "react";
import Modal from "react-modal"; // Modal ko import kiya
import "./ProfileImageUpload.css";
import uploadImg from "../../../../assets/imgs/uploadIcon.png";
import fileImg from "../../../../assets/imgs/file.png";
import CompleteProfileImageUplaod from "./CompleteProfileImageUplaod";

Modal.setAppElement("#root"); // Accessibility ke liye zaroori hai

const ProfileImageUpload = ({ isOpen, onClose, candidate }) => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      width: "45%",
      marginRight: "-50%",
      position: "absolute",
      transform: "translate(-50%, -50%)",
      borderRadius: "10px",
      padding: "0px",
    },
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        contentLabel="Upload Photo"
        onRequestClose={onClose}
        style={customStyles}
      >
        <div className="popup-content">
          <button onClick={onClose} className="close-btn">
            X
          </button>

          <CompleteProfileImageUplaod onClose={onClose} candidate={candidate} />
        </div>
      </Modal>
    </div>
  );
};

export default ProfileImageUpload;
