import React, { useState, useContext, useCallback } from "react";
import "./BasicInformation.css";
import plusIcon from "../../../../assets/imgs/Edit.png";
import { AuthContext } from "../../../../context/AuthContext";
import UpdateBasicInformation from "./UpdateBasicInformationPopup";

function BasicInformation({ candidate }) {
  const { user } = useContext(AuthContext);

  // Modal ko handle karne ke liye state
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Modal ko handle karne ke liye useCallback istamaal kiya for memoization
  const openModal = useCallback(() => setIsModalOpen(true), []);
  const closeModal = useCallback(() => setIsModalOpen(false), []);

  return (
    <div className="basicInformation">
      <h4>Basic Information</h4>

      <div className="informationList">
        {[
          { label: "Name", value: candidate.name },
          { label: "Candidate Type", value: candidate.type },
          { label: "ID Number", value: `ID${candidate.recruiterFlowId}` },
          { label: "Email", value: user.email },
          {
            label: "Location",
            value:
              candidate.city && candidate.state
                ? `${candidate.city}, ${candidate.state}`
                : null,
          },
          { label: "Phone", value: candidate.phone },
        ].map(({ label, value }) => (
          <div key={label} className="list">
            <p>{label}</p>
            <p>
              <b>{value}</b>
            </p>
          </div>
        ))}

        {!user.isGoogleLogin && (
          <div className="list-password">
            <p>Password</p>
            <p>
              <b>Set a new Password</b>
            </p>
          </div>
        )}
      </div>

      <div className="editIcon" onClick={openModal}>
        <img src={plusIcon} alt="Edit icon" />
      </div>

      {/* Modal ko modal ka state pass karke render kiya */}
      <UpdateBasicInformation
        isOpen={isModalOpen}
        onClose={closeModal}
        candidate={candidate}
      />
    </div>
  );
}

export default BasicInformation;
