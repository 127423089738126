import React, { useState } from "react";
import "./Resume.css";
import resumeIcon from "../../../../assets/imgs/Resume.png";
import plusIcon from "../../../../assets/imgs/plusCircle.png";
import UploadResume from "./UploadResume/UploadResume";
import pdfImg from "../../../../assets/imgs/pdfIcon.png";
import ViewResume from "./ViewResume";
import EditIcon from "../../../../assets/imgs/Edit.png";
function Resume({ candidate }) {
  // Modal ko handle karne ke liye state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalResumeOpen, setIsModalResumeOpen] = useState(false);

  // Modal open karne ka function
  const openModal = () => {
    setIsModalOpen(true);
  };

  const openResumeModal = () => {
    setIsModalResumeOpen(true);
  };

  const closeResumeModal = () => {
    setIsModalResumeOpen(false);
  };

  // Modal band karne ka function
  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div className="resume-sec ">
      <h4>Resume</h4>

      {candidate.resumeUrl ? (
        <div className="show-state">
          <div className="editIcon" onClick={openModal}>
            <img src={EditIcon} alt="plus-icon" />
          </div>
          <div className="resume-link">
            <div>
              <img src={pdfImg} alt="resume-icon" />
              <span style={{ paddingLeft: "5px" }}>
                {candidate.fname}-Resume...
              </span>
            </div>
            <div>
              <button onClick={openResumeModal}>View Resume</button>
            </div>
          </div>
        </div>
      ) : (
        <div className="empty-state">
          <div className="plusIcon" onClick={openModal}>
            <img src={plusIcon} alt="plus-icon" />
          </div>
          <img src={resumeIcon} alt="resumeIcon" />
          <p>
            A resume is crucial for attracting potential employers. Upload your
            resume to give companies a complete view of your qualifications.
          </p>
          <button onClick={openModal}>Upload Resume</button>
        </div>
      )}

      <UploadResume
        isOpen={isModalOpen}
        onClose={closeModal}
        candidate={candidate}
      />

      <ViewResume
        isOpen={isModalResumeOpen}
        onClose={closeResumeModal}
        candidate={candidate}
      />
    </div>
  );
}

export default Resume;
