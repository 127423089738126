import React, { useState } from "react";
import "./Register.css";
import { Link, useNavigate } from "react-router-dom";
import userImg from "../../../assets/imgs/userblank.png";
import partnerIcon from "../../../assets/imgs/partnerIcon.png";
import loginLogo from "../../../assets/imgs/7eagle-logo.png";
import bgImg from "../../../assets/imgs/bg.png";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../../../components/company/dashboard/Header/Header";

const PreRegister = () => {
  // State For New Company

  const [isCompany, setIsCompany] = useState(true);
  const [isUser, setIsUser] = useState(false);

  const navigate = useNavigate();

  // New Company Functions
  const handleUserTypeChange = (type) => {
    setIsCompany(type === "company");
    setIsUser(type === "user");
  };

  const handleCompanyRegister = () => {
    navigate("/company/register");
  };

  const handleCandidateRegister = () => {
    navigate("/candidate/register");
  };

  return (
    <>
      <Header />
      <ToastContainer />
      <div className="register-container">
        <div className="row register-row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="register-banner">
              <img src={bgImg} alt="company-banner" />
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="form main-box">
              <>
                <>
                  <img
                    src={loginLogo}
                    alt="company-logo"
                    className="company-logo"
                  />
                  <h2 className="user-type-heading">
                    Join as a Partner <br />
                    or Military Talent
                  </h2>
                </>

                <div className="user-type-selection">
                  <div
                    className={`selection-box ${
                      isCompany ? "active-company" : ""
                    }`}
                  >
                    <label>
                      <img src={partnerIcon} alt="partnericon" />
                      <input
                        type="radio"
                        checked={isCompany}
                        onChange={() => handleUserTypeChange("company")}
                      />
                    </label>
                    <p>I’m a partner company looking for top military talent</p>
                  </div>
                  <div
                    className={`selection-box ${isUser ? "active-user" : ""}`}
                  >
                    <label>
                      <img src={userImg} alt="userImg" />
                      <input
                        type="radio"
                        checked={isUser}
                        onChange={() => handleUserTypeChange("user")}
                      />
                    </label>
                    <p>I’m a military talent looking for great companies</p>
                  </div>
                </div>

                {isCompany && (
                  <div className="button-container">
                    <button
                      className="company-register-btn"
                      onClick={handleCompanyRegister}
                    >
                      Join as Partner Company
                    </button>
                    <p className="login-link selection-login">
                      Already have an account? <Link to="/login">Login</Link>
                    </p>
                  </div>
                )}

                {isUser && (
                  <div className="button-container">
                    <button
                      className="user-register-btn"
                      onClick={handleCandidateRegister}
                    >
                      Join as Talent
                    </button>
                    <p className="login-link selection-login">
                      Already have an account?{" "}
                      <Link to="/company/login">Login</Link>
                    </p>
                  </div>
                )}
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PreRegister;
