import React, { useState, useContext } from "react";
//import profileImage from "../../../../assets/imgs/profileDefaultCandidate.png"; // Profile image placeholder
import pinImg from "../../../../assets/imgs/Pin.png"; // Profile image placeholder
import profileEditIcon from "../../../../assets/imgs/profileEdit.png"; // Profile image placeholder
import { AuthContext } from "../../../../context/AuthContext";
import "../Profile/CandidateProfileBox.css"; // For Settings component
import { Link } from "react-router-dom";
import ProfileImagePopup from "../ProfileImagePopup/ProfileImagePopup";
import ProfileImageUpload from "../ProfileImagePopup/ProfileImageUpload";

function CandidateProfileBox({ candidate }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { user } = useContext(AuthContext);
  // Modal open aur close karne ka function
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const BASE_URL = `${process.env.REACT_APP_API_URL}`;
  const profileImage = `${BASE_URL}/${candidate.headshotLink}`;

  return (
    <>
      <div className="profile-box-wrap-dashboard">
        <div className="left-box">
          <div className="img-box profile-box-img">
            <div>
              {candidate.headshotLink ? (
                <div className="exist-profile-image">
                  <img
                    src={profileImage}
                    alt="Company logo"
                    className="company-logo-img"
                  />
                </div>
              ) : (
                <div className="ProfileDefaultImg">
                  <h2>{candidate.name.charAt(0)}</h2>
                </div>
              )}
            </div>
            <div
              className="profileEdit"
              onClick={openModal}
              candidate={candidate}
            >
              <img src={profileEditIcon} alt="profile-edit-icon" />
            </div>
          </div>
          <div className="company-name-dashboard">
            <h2>{candidate.name}</h2>
            <h5 className="candidateIdentity">
              <span className="candidateType">{candidate.type}</span> (
              {`ID${candidate.recruiterFlowId}`})
            </h5>
            <h6>
              {candidate.city && candidate.state && (
                <>
                  <img
                    src={pinImg}
                    alt="pinImg"
                    style={{
                      width: "20px",
                      marginRight: "7px",
                      padding: "0px",
                    }}
                  />
                  {candidate.city}, {candidate.state}
                </>
              )}
            </h6>
          </div>
        </div>

        <div className="right-box">
          <div className="viewprofile">
            {/* Corrected diiv to div */}
            <Link to="/candidate/profile-page">
              <button
                style={{
                  width: "100%",
                  padding: "10px 30px",
                  fontWeight: 500,
                }}
              >
                View Public Profile
              </button>
            </Link>
          </div>
        </div>
      </div>
      <ProfileImageUpload
        isOpen={isModalOpen}
        onClose={closeModal}
        candidate={candidate}
      />
    </>
  );
}

export default CandidateProfileBox;
