// src/components/PrivateRoute.js
import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext"; // Import AuthContext

const PrivateRoute = ({ children }) => {
  const { isAuthenticated } = useContext(AuthContext); // Use context to get authentication status

  return isAuthenticated ? children : <Navigate to="/candidate/login" />;
};

export default PrivateRoute;
