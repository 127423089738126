import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CompanyLogin from "./components/company/User/Login";
import CompanyRegister from "./components/company/User/Register";
import CandidateRegister from "./components/candidate/User/CandidateRegister";
import CandidateLogin from "./components/candidate/User/CandidateLogin";

import Dashboard from "./pages/company/Dashboard";
import Profile from "./pages/company/Profile";
import { AuthProvider } from "./context/AuthContext"; // Import AuthProvider
import PrivateRoute from "./components/PrivateRoute"; // Import PrivateRoute
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CompanyPublic from "./pages/company/CompanyPublic";
import BrowseCandidate from "./pages/company/BrowseCandidate";
import HotCandidates from "./pages/company/HotCandidates";
import FavoriteCandidates from "./pages/company/FavoriteCandidates";
import MatchCandidates from "./pages/company/MatchCandidates";
import ScheduledCandidates from "./pages/company/ScheduledCandidate";
import EditProfile from "./pages/company/EditProfile";
import ForgotPassword from "./components/company/User/ForgotPassword";
import PreRegister from "./components/company/User/PreRegister";
import PreLogin from "./components/company/User/PreLogin";
import CandidateForgotPassword from "./components/candidate/User/CandidateForgotPassword";
import CandidateDashboard from "./pages/candidate/CandidateDashboard";
import CandidateCompleteProfile from "./pages/candidate/CandidateCompleteProfile";
import CandidateProfilePage from "./pages/candidate/CandidateProfilePage";

function App() {
  return (
    <Router>
      <AuthProvider>
        {" "}
        {/* Wrap AuthProvider inside Router */}
        <Routes>
          <Route path="/company/login" element={<CompanyLogin />} />
          <Route path="/company/register" element={<CompanyRegister />} />
          <Route path="/candidate/register" element={<CandidateRegister />} />
          <Route path="/candidate/login" element={<CandidateLogin />} />

          <Route path="/register" element={<PreRegister />} />
          <Route path="/login" element={<PreLogin />} />

          <Route path="/company/forgot-password" element={<ForgotPassword />} />
          <Route
            path="/candidate/forgot-password"
            element={<CandidateForgotPassword />}
          />

          {/* PrivateRoute ko yahan use karein */}
          <Route
            path="/company/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/company/profile"
            element={
              <PrivateRoute>
                <Profile />
              </PrivateRoute>
            }
          />
          <Route
            path="/company/search-candidate"
            element={
              <PrivateRoute>
                <BrowseCandidate />
              </PrivateRoute>
            }
          />
          <Route
            path="/company/hot-candidates"
            element={
              <PrivateRoute>
                <HotCandidates />
              </PrivateRoute>
            }
          />
          <Route
            path="/company/favorite-candidates"
            element={
              <PrivateRoute>
                <FavoriteCandidates />
              </PrivateRoute>
            }
          />
          <Route
            path="/company/match-candidates"
            element={
              <PrivateRoute>
                <MatchCandidates />
              </PrivateRoute>
            }
          />
          <Route
            path="/company/scheduled-candidates"
            element={
              <PrivateRoute>
                <ScheduledCandidates />
              </PrivateRoute>
            }
          />
          <Route
            path="/company/edit-profile"
            element={
              <PrivateRoute>
                <EditProfile />
              </PrivateRoute>
            }
          />

          <Route
            path="/candidate/dashboard"
            element={
              <PrivateRoute>
                <CandidateDashboard />
              </PrivateRoute>
            }
          />

          <Route
            path="/candidate/complete-profile"
            element={
              <PrivateRoute>
                <CandidateCompleteProfile />
              </PrivateRoute>
            }
          />

          <Route
            path="/candidate/profile-page"
            element={
              <PrivateRoute>
                <CandidateProfilePage />
              </PrivateRoute>
            }
          />

          <Route path="/company/public" element={<CompanyPublic />} />

          {/* Other routes for your application */}
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;
