import React, { useState } from "react";
import "./CandidateAccountProgress.css";

function CandidateAccountProgress({ candidate }) {
  // Candidate information ko check karna
  const hasBasicInfo =
    candidate.fname &&
    candidate.lname &&
    candidate.phone &&
    candidate.city &&
    candidate.state;

  const hasMilitaryBackground =
    candidate.branch &&
    candidate.rank &&
    candidate.mos &&
    candidate.yearsServed;

  const hasCompleteProfileInfo =
    candidate.description &&
    candidate.securityClearance &&
    candidate.yearsOfExperience &&
    candidate.idealJobTitle &&
    candidate.relocationprefrence;

  const hasProfilePhoto = candidate.headshotLink;

  // Progress percentage calculate karna
  const totalSections = 4; // Total sections that need to be completed
  let completedSections = 0;

  if (hasBasicInfo) completedSections++;
  if (hasMilitaryBackground) completedSections++;
  if (hasProfilePhoto) completedSections++;
  if (hasCompleteProfileInfo) completedSections++;

  const progress = (completedSections / totalSections) * 100; // Progress percentage

  // Circle ka radius aur circumference
  const radius = 50; // Circle ka radius
  const circumference = 2 * Math.PI * radius;

  // Ensure ke initial offset properly set ho clockwise animation ke liye
  const offset = circumference - (progress / 100) * circumference;

  return (
    <div className="accountProgress_sec">
      <h4>Account Progress</h4>
      <div className="row row-circle">
        <div className="col-lg-12 ">
          <div className="circle-box">
            <svg width="120" height="120">
              <circle
                cx="60"
                cy="60"
                r={radius}
                stroke="#C6FFB2"
                strokeWidth="10"
                fill="none"
              />
              <circle
                cx="60"
                cy="60"
                r={radius}
                stroke="#1E6B02" // Updated stroke color
                strokeWidth="10"
                fill="none"
                strokeDasharray={`${circumference} ${circumference}`}
                strokeDashoffset={offset} // Properly set offset
                transform="rotate(-90 60 60)" // Rotate around center
                style={{ transition: "stroke-dashoffset 0.5s ease" }} // Smooth transition
              />
              <text
                x="60"
                y="70"
                textAnchor="middle"
                fontSize="20"
                fill="#1E6B02"
              >
                {Math.round(progress)}%
              </text>
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CandidateAccountProgress;
