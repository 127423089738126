// src/components/Login.js
import React, { useState, useContext, useEffect } from "react"; // Import useContext
import axios from "axios";
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import { AuthContext } from "../../../context/AuthContext"; // Import AuthContext
import "./Login.css"; // CSS file for styling
import Header from "../../candidate/dashboard/Header/Header";
import loginLogo from "../../../assets/imgs/7eagle-logo.png";
import bgImg from "../../../assets/imgs/bg.png";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import trueImg from "../../../assets/imgs/true.png";
import GoogleLoginButton from "./GoogleLogin";
import alertImg from "../../../assets/imgs/alert.png";
import userFieldIcon from "../../../assets/imgs/userFieldIcon.png";

import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const { login } = useContext(AuthContext); // Get login function from AuthContext
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  // const [message, setMessage] = useState('');
  const [keepSignedIn, setKeepSignedIn] = useState(false); // State for Keep Me Signed In
  const navigate = useNavigate(); // useNavigate hook for navigation
  const [isCandidate, setIsCandidate] = useState(true);

  const [formData, setFormData] = useState({
    candidateType: "",
    branch: "",
  });

  // Common Functions
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const [step, setStep] = useState(0);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [userData, setUserData] = useState();

  const handleLoginSuccess = async (data) => {
    setUserData(data);
  };

  useEffect(() => {
    if (userData) {
    }
  }, [userData]);

  const googleCreateAccount = async () => {
    const mergedData = {
      ...userData,
      branch: formData.branch,
      type: formData.candidateType,
    };

    setUserData(mergedData);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/api/users/google-register-candidate`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(mergedData),
        }
      );

      if (res.ok) {
        const data = await res.json();
        toast.success("User registered successfully!");
        setStep(4);
      } else {
        const data = await res.json();
        if (data.message === "User already exists!") {
          toast.error("User already exists!");
          setStep(3);
        } else {
          toast.error("User Registration Failed!");
        }
      }
    } catch (error) {
      toast.error("Error: Something went wrong!");
      console.error("Error:", error);
    }
  };

  const loginStep = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/users/check-email`,
        {
          email,
        }
      );

      setStep(1);
    } catch (error) {
      if (error.response.data.message === "Invalid Account!") {
        setStep(2);
      } else {
        toast.error(
          error.response ? error.response.data.message : "Login Failed"
        );
      }
    }
  };

  const TryAgainLogin = () => {
    setStep(0);
    setEmail("");
  };

  const changeLoginState = () => {
    setStep(0);
  };
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        ` ${process.env.REACT_APP_API_URL}/api/users/login`,
        {
          email,
          password,
        }
      );

      const token = response.data.token;
      const userData = response.data.user;

      if (token) {
        // Store token and user data in local storage or state
        localStorage.setItem("token", token);
        localStorage.setItem("user", JSON.stringify(userData)); // Store user data
        login(token, userData); // Pass the user data to your login function
        toast.success("Thank you for logging in!");
      } else {
        toast.error("Login failed: No token received");
      }
    } catch (error) {
      toast.error(
        error.response ? error.response.data.message : "Login Failed"
      );
    }
  };

  return (
    <div className="main-login">
      <Header />
      <ToastContainer />
      <div className="row" style={{ alignItems: "center" }}>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div className="login-banner">
            <img src={bgImg} alt="company-banner" />
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div>
            <div className="login-container">
              {isCandidate && (
                <>
                  <form onSubmit={handleLogin}>
                    {step === 0 && (
                      <>
                        <img
                          src={loginLogo}
                          alt="company-logo"
                          className="company-logo"
                        />
                        <h2>Login to 7 Eagle Group</h2>

                        <div className="emailfield-group">
                          <img src={userFieldIcon} alt="userIcon" />
                          <input
                            type="text"
                            placeholder="Username / Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                          />
                        </div>

                        <button
                          style={{ marginTop: "10px" }}
                          className="login-btn-step0"
                          onClick={loginStep}
                        >
                          Continue
                        </button>

                        <p
                          style={{
                            textAlign: "center",
                            paddingBottom: "0px",
                            paddingTop: "10px",
                            marginBottom: "0px",
                            color: "#8d9296",
                          }}
                        >
                          Or
                        </p>

                        <div
                          className="google-btn"
                          style={{ marginTop: "10px" }}
                        >
                          <GoogleLoginButton
                            setStep={setStep}
                            onSuccess={handleLoginSuccess}
                          />
                        </div>

                        <p className="signup-link">
                          Don’t have a 7 Eagle Account?
                        </p>

                        <div className="signup-btn">
                          <Link to="/candidate/register">Sign Up</Link>
                        </div>
                      </>
                    )}
                    {step === 1 && (
                      <>
                        <img
                          src={loginLogo}
                          alt="company-logo"
                          className="company-logo"
                        />
                        <h2 style={{ marginBottom: "10px" }}>Welcome Back</h2>
                        <p style={{ textAlign: "center" }}>{email}</p>
                        <div className="password-field">
                          <input
                            type={showPassword ? "text" : "password"}
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                          />

                          <span
                            onClick={togglePasswordVisibility}
                            className="password-toggle-icon"
                          >
                            {showPassword ? (
                              <FaEyeSlash size={24} color="#7D8287" />
                            ) : (
                              <FaEye size={24} color="#7D8287" />
                            )}
                          </span>
                        </div>

                        <div className="keepSign">
                          <div className="keep-sub">
                            <input
                              className="checkbox"
                              type="checkbox"
                              checked={keepSignedIn}
                              onChange={(e) =>
                                setKeepSignedIn(e.target.checked)
                              }
                            />
                            <label>Keep Me Signed In</label>
                          </div>

                          <div className="keep-sub">
                            <Link
                              to={`/candidate/forgot-password?email=${encodeURIComponent(
                                email
                              )}`}
                            >
                              Forgot password?
                            </Link>
                          </div>
                        </div>

                        <button type="submit">Login</button>

                        <p className="signup-link">
                          Don’t have a 7 Eagle Account?
                        </p>

                        <div className="signup-btn">
                          <Link to="/candidate/register">Sign Up</Link>
                        </div>
                      </>
                    )}
                  </form>
                </>
              )}

              {step === 2 && (
                <div className="invalid-account">
                  <img src={alertImg} alt="alertimg" className="alertImg" />
                  <h2 style={{ marginBottom: "10px" }}>User Not Found</h2>
                  <p style={{ textAlign: "center" }}>
                    It looks like we couldn’t find your account in our system.
                    If you’re new here, let’s get you signed up!
                  </p>
                  <Link to="/candidate/register">
                    <button className="login" style={{ marginTop: "10px" }}>
                      Create a Free Account Today
                    </button>
                  </Link>

                  <p style={{ paddingTop: "20px", textAlign: "center" }}>
                    Already have an account?{" "}
                    <b style={{ cursor: "pointer" }} onClick={TryAgainLogin}>
                      Try Logging In Again
                    </b>
                  </p>
                </div>
              )}

              {step === 4 && (
                <div className="done-registed-box">
                  <img src={trueImg} className="trueImg" />
                  <h3>Account Successfully Registered</h3>
                  <p>You can now start using your account!</p>
                  <div>
                    <Link to="/candidate/login" className="Login-btn-done">
                      Login Your Account!
                    </Link>
                  </div>
                </div>
              )}

              {step === 5 && (
                <div className="done-registed-box">
                  <img src={trueImg} className="trueImg" />
                  <h3>You’re Almost There!</h3>
                  <p>
                    Your Google account has been successfully connected.
                    Complete your registration by providing your candidate
                    details
                  </p>

                  <div className="google-btn">
                    <GoogleLoginButton
                      setStep={setStep}
                      onSuccess={handleLoginSuccess}
                    />
                  </div>

                  <div className="company-wrap field-combind">
                    <label>
                      Military Branch <span>*</span>
                    </label>
                    <input
                      type="text"
                      name="branch"
                      placeholder="Type here"
                      value={formData.branch}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <label>
                    Candidate Type <span>*</span>
                  </label>

                  <div className="checkbox-main">
                    <div
                      className="checkboxs"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <input
                        type="radio"
                        value="Transitioning Service Member"
                        checked={
                          formData.candidateType ===
                          "Transitioning Service Member"
                        }
                        name="candidateType"
                        onChange={handleChange} // Save the value using the handleChange
                        style={{ width: "20px", marginRight: "10px" }}
                      />
                      <span>Transitioning Service Member</span>
                    </div>

                    <div
                      className="checkboxs"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <input
                        type="radio"
                        value="Veteran"
                        name="candidateType"
                        checked={formData.candidateType === "Veteran"}
                        onChange={handleChange} // Save the value using the handleChange
                        style={{ width: "20px", marginRight: "10px" }}
                      />
                      <span>Veteran</span>
                    </div>
                  </div>

                  <button
                    className="company-register-btn"
                    type="button"
                    onClick={googleCreateAccount}
                  >
                    Create Free Account Today
                  </button>

                  <p className="login-link selection-login">
                    Already have an account?{" "}
                    <Link to="/candidate/login">Login</Link>
                  </p>
                </div>
              )}

              {step === 6 && (
                <div className="already-registed-box">
                  <img src={alertImg} className="alertImg" />
                  <h3>
                    This account is already registered, but not with Google.
                  </h3>
                  <p>
                    The email address you provided <b>{formData.email}</b> is
                    already registered in our database. If you would like to
                    regain access to your account.
                  </p>

                  <Link
                    to="/candidate/login"
                    className="Login-btn-done"
                    onClick={changeLoginState}
                  >
                    Login Your Account!
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
