import React, { createContext, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const storedToken =
      localStorage.getItem("token") || sessionStorage.getItem("token");
    const storedUser =
      localStorage.getItem("user") || sessionStorage.getItem("user");

    if (storedToken && storedUser) {
      setIsAuthenticated(true);
      setToken(storedToken);
      setUser(JSON.parse(storedUser)); // Parse the stored user data
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  useEffect(() => {
    if (
      isAuthenticated === false &&
      location.pathname !== "/company/register" &&
      location.pathname !== "/register" &&
      location.pathname !== "/login" &&
      location.pathname !== "/candidate/register" &&
      location.pathname !== "/candidate/login" &&
      location.pathname !== "/company/login" &&
      location.pathname !== "/company/forgot-password" &&
      location.pathname !== "/candidate/forgot-password"
    ) {
      navigate("/candidate/login");
    }
  }, [isAuthenticated, navigate, location]);

  const login = (userToken, userData, keepSignedIn) => {
    setIsAuthenticated(true);
    setUser(userData);
    setToken(userToken);

    if (keepSignedIn) {
      localStorage.setItem("token", userToken);
      localStorage.setItem("user", JSON.stringify(userData)); // Store user data
    } else {
      sessionStorage.setItem("token", userToken);
      sessionStorage.setItem("user", JSON.stringify(userData)); // Store user data
    }

    if (userData.userType === "Company") {
      navigate("/company/dashboard");
    } else if (userData.userType === "Candidate") {
      navigate("/candidate/dashboard");
    } else {
      console.error("Unknown user type:", userData.userType);
      navigate("/");
    }
  };

  const logout = () => {
    setIsAuthenticated(false);
    setUser(null);
    setToken(null);

    localStorage.removeItem("token");
    localStorage.removeItem("user");
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("user");

    const userType = user ? user.userType : null;

    if (userType === "Company") {
      navigate("/company/login");
    } else if (userType === "Candidate") {
      navigate("/candidate/login");
    } else {
      navigate("/login");
    }
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, user, token, login, logout }}
    >
      {isAuthenticated !== null && children}
    </AuthContext.Provider>
  );
};
