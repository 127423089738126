import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Register.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import userImg from "../../../assets/imgs/userblank.png";
import partnerIcon from "../../../assets/imgs/partnerIcon.png";
import loginLogo from "../../../assets/imgs/7eagle-logo.png";
import bgImg from "../../../assets/imgs/bg.png";
import dummyLogo from "../../../assets/imgs/Rectangle.png";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../../../components/company/dashboard/Header/Header";
import RegistrationForm from "./RegistratonForm";
import ExistingRegistrationForm from "./ExistingRegistrationForm";

const Register = () => {
  // State For New Company
  const [step, setStep] = useState(1);
  const [showPassword, setShowPassword] = useState(false);
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [formData, setFormData] = useState({
    companyname: "",
    fname: "",
    lname: "",
    title: "",
    email: "",
    phone: "",
    password: "",
    userType: "Company",
  });
  const [isCompany, setIsCompany] = useState(true);

  const [showForm, setShowForm] = useState(false);

  // State For Existing Company

  const [showExistingForm, setShowExistingForm] = useState(false);
  const [existingUser, setExistingUser] = useState(null); // Initially set to null
  const [existingCompanyName, setExistingCompanyName] = useState("");
  const [existingCompanyData, setExistingCompanyData] = useState("");

  const [existingCompanyImg, setExistingCompanyImg] = useState("");

  const navigate = useNavigate();
  const location = useLocation();

  // Existing Company Functions
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const companyId = queryParams.get("companyId");

    if (companyId) {
      axios
        .get(`http://localhost:5000/api/tempcompanies/${companyId}`)
        .then((response) => {
          if (response.data) {
            setExistingUser(true);
            console.log(response.data);
            setExistingCompanyName(response.data.name);
            setExistingCompanyImg(response.data.coverPhoto);
            setExistingCompanyData(response.data);
          } else {
            navigate("/company/register");
          }
        })
        .catch((error) => {
          console.error("Error fetching company data:", error);
          navigate("/company/register");
        });
    } else {
      setExistingUser(false); // If no companyId in query params, set to false
    }
  }, [location.search, navigate]);

  const [formExistingData, setFormExistingData] = useState({
    companyname: existingCompanyName,
    fname: "",
    lname: "",
    title: "",
    email: "",
    phone: "",
    password: "",
    userType: "Company",
  });

  const handleShowExistingForm = () => {
    setShowExistingForm(true);
  };

  const handleExistingRegister = async (e) => {
    e.preventDefault();

    const otpCode = otp.join("");

    try {
      const otpResponse = await axios.post(
        "http://localhost:5000/api/users/verify-otp",
        {
          email: formExistingData.email,
          otp: otpCode,
          password: formExistingData.password,
          userType: formExistingData.userType,
          fname: formExistingData.fname,
          lname: formExistingData.lname,
          phone: formExistingData.phone,
          companyname: existingCompanyData.name,
          logo: existingCompanyData.logo,
          city: existingCompanyData.city,
          companyImages: existingCompanyData.companyImages,
          companySize: existingCompanyData.companySize,
          companyWebsite: existingCompanyData.companyWebsite,
          coverPhoto: existingCompanyData.coverPhoto,
          description: existingCompanyData.description,
          hqLocation: existingCompanyData.hqLocation,
          industry: existingCompanyData.industry,
          linkedinUrl: existingCompanyData.linkedinUrl,
          profileUrl: existingCompanyData.profileUrl,
          remoteOpportunity: existingCompanyData.remoteOpportunity,
          state: existingCompanyData.state,
          veteranOwned: existingCompanyData.veteranOwned,
          videoOverview: existingCompanyData.videoOverview,
        }
      );

      if (otpResponse.data.message === "User registered successfully") {
        toast.success(otpResponse.data.message);

        setFormData({
          companyname: "",
          fname: "",
          lname: "",
          title: "",
          email: "",
          phone: "",
          password: "",
          userType: "Company",
        });

        setStep(4);
      } else {
        toast.error("Unexpected response during registration.");
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
        if (
          error.response.data.message === "User already exists with this email"
        ) {
          setStep(3);
        }
        if (error.response.data.message === "Company already exists") {
          setStep(3);
        }
      } else {
        toast.error("Registration failed. Please try again.");
      }
    }
  };

  const sendOtpExistingCompany = async () => {
    if (step === 1) {
      if (
        !formExistingData.email ||
        !formExistingData.phone ||
        !formExistingData.fname ||
        !formExistingData.lname ||
        !formExistingData.userType ||
        !formExistingData.password
      ) {
        toast.error("Please fill out all required fields.");
        return;
      }
      try {
        const response = await axios.post(
          "http://localhost:5000/api/users/send-otp",
          { email: formExistingData.email }
        );
        toast.success(response.data.message);
        setStep(2);
      } catch (error) {
        toast.error(
          error.response ? error.response.data.message : "Failed to send OTP"
        );
      }
    }
  };

  // Common Functions
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setFormExistingData({ ...formExistingData, [name]: value });
  };

  const handleOtpChange = (element, index) => {
    let otpArray = [...otp];
    otpArray[index] = element.value;
    setOtp(otpArray);

    if (element.nextSibling && element.value) {
      element.nextSibling.focus();
    }
  };

  const sendOtp = async () => {
    if (step === 1) {
      if (
        !formData.email ||
        !formData.phone ||
        !formData.companyname ||
        !formData.fname ||
        !formData.lname ||
        !formData.userType ||
        !formData.password
      ) {
        toast.error("Please fill out all required fields.");
        return;
      }
      try {
        const response = await axios.post(
          "http://localhost:5000/api/users/send-otp",
          { email: formData.email }
        );
        toast.success(response.data.message);
        setStep(2);
      } catch (error) {
        toast.error(
          error.response ? error.response.data.message : "Failed to send OTP"
        );
      }
    }
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    const otpCode = otp.join("");

    try {
      const otpResponse = await axios.post(
        "http://localhost:5000/api/users/verify-otp",
        {
          email: formData.email,
          otp: otpCode,
          password: formData.password,
          userType: formData.userType,
          fname: formData.fname,
          lname: formData.lname,
          phone: formData.phone,
          companyname: formData.companyname,
        }
      );

      if (otpResponse.data.message === "User registered successfully") {
        toast.success(otpResponse.data.message);

        setFormData({
          companyname: "",
          fname: "",
          lname: "",
          title: "",
          email: "",
          phone: "",
          password: "",
          userType: "Company",
        });

        setStep(4);
      } else {
        toast.error("Unexpected response during registration.");
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
        if (
          error.response.data.message === "User already exists with this email"
        ) {
          setStep(3);
        }
        if (error.response.data.message === "Company already exists") {
          setStep(3);
        }
      } else {
        toast.error("Registration failed. Please try again.");
      }
    }
  };

  return (
    <>
      <Header />
      <ToastContainer />
      <div className="register-container">
        <div className="row register-row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="register-banner">
              <img src={bgImg} alt="company-banner" />
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="form main-box">
              {existingUser === null ? ( // Render loading or nothing while determining existingUser
                <></>
              ) : existingUser ? (
                <>
                  {!showExistingForm && (
                    <div className="existing-company-box">
                      <img
                        src={loginLogo}
                        alt="company-logo"
                        className="company-logo"
                      />
                      <h4>Welcome to 7 Eagle Group</h4>
                      <p style={{ textAlign: "center" }}>
                        We’ve set up your account for{" "}
                        <b>{existingCompanyName}</b> If this is your company,
                        let's get you started.
                      </p>

                      <div className="company-profile-box">
                        <img src={dummyLogo} alt="company-logo" />
                        <b>{existingCompanyName}</b>
                      </div>

                      <button
                        className="existingCompany-btn"
                        onClick={handleShowExistingForm}
                      >
                        Get Started
                      </button>
                    </div>
                  )}

                  {showExistingForm && (
                    <div className="existing-company-form">
                      <ExistingRegistrationForm
                        step={step}
                        formExistingData={formExistingData}
                        handleChange={handleChange}
                        handleExistingRegister={handleExistingRegister}
                        showPassword={showPassword}
                        togglePasswordVisibility={togglePasswordVisibility}
                        sendOtpExistingCompany={sendOtpExistingCompany}
                        prevStep={prevStep}
                        handleOtpChange={handleOtpChange}
                        otp={otp}
                        setStep={setStep}
                        existingCompanyName={existingCompanyName}
                      />
                    </div>
                  )}
                </>
              ) : (
                <>
                  {isCompany && (
                    <RegistrationForm
                      step={step}
                      formData={formData}
                      handleChange={handleChange}
                      handleRegister={handleRegister}
                      showPassword={showPassword}
                      togglePasswordVisibility={togglePasswordVisibility}
                      sendOtp={sendOtp}
                      prevStep={prevStep}
                      handleOtpChange={handleOtpChange}
                      otp={otp}
                      setStep={setStep}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
