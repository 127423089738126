export const industriesList = [
  " Recruiting",
  "Aviation, Aerospace, & Drones",
  " Education",
  "Farming, Agriculture & Cannabis",
  " Banking, Finance & Insurance",
  "Government & Defense",
  " Healthcare & Health Science",
  "Tourism & Hospitality",
  " Hunting, Fishing, Hiking, & All Outdoor Sports",
  "Information Technology - Cyber Security, Software Development & Engineering",
  " Accounting & Legal",
  " Manufacturing, Construction, Oil & Gas, Automotive",
  " Non Profit",
  " Physical Security",
  " Real Estate",
  " Restaurant",
  " Solar & Green Energy",
  "Sports & Entertainment",
  "Telecommunications",
  " Transportation",
];
