import React, { useState, useContext } from "react";
import Modal from "react-modal";
import "./AddCertificate.css"; // Ensure you have this CSS file for styling
import { certificationsList } from "./CertificationLists"; // Assuming this contains your certifications
import { AuthContext } from "../../../../../context/AuthContext"; // Adjust the path as needed
import { toast, ToastContainer } from "react-toastify"; // Ensure you have react-toastify installed
Modal.setAppElement("#root");

const AddCertificate = ({ isOpen, onClose, candidate }) => {
  const { user } = useContext(AuthContext);
  const [currentCertifications, setCurrentCertifications] = useState(
    candidate.certifications || ""
  );
  const [pursuingCertifications, setPursuingCertifications] = useState(
    candidate.Pursuingcertifications || ""
  );
  const [inputValue, setInputValue] = useState("");
  const [pursuingInputValue, setPursuingInputValue] = useState("");
  const [currentSuggestions, setCurrentSuggestions] = useState([]);
  const [pursuingSuggestions, setPursuingSuggestions] = useState([]);

  // Initialize form data with candidate information
  const [formData, setFormData] = useState({
    fname: candidate.fname || "",
    lname: candidate.lname || "",
    candidateType: candidate.type || "",
    state: candidate.state || "",
    city: candidate.city || "",
    branch: candidate.branch || "",
    description: candidate.description || "",
    securityClearance: candidate.securityClearance || "",
    yearsOfExperience: candidate.yearsOfExperience || "",
    skillbridgeStartDate: candidate.skillbridgeStartDate || "",
    skillbridgeEndDate: candidate.skillbridgeEndDate || "",
    idealJobTitle: candidate.idealJobTitle || "",
    duringSkillbridgeCity: candidate.duringSkillbridgeCity || "",
    duringSkillbridgeState: candidate.duringSkillbridgeState || "",
    afterskillbridgeCity: candidate.afterskillbridgeCity || "",
    afterskillbridgeState: candidate.afterskillbridgeState || "",
    relocationprefrence: candidate.relocationprefrence || "",
    education: candidate.education || "",
    idno: candidate.recruiterFlowId || "", // Yeh ID RecruiterFlow ki hai
    email: candidate.email || "",
    phone: candidate.phone || "",
    certifications: candidate.certifications || "",
    Pursuingcertifications: candidate.Pursuingcertifications || "",
    branch: candidate.branch || "", // Optional chaining
    rank: candidate.rank || "",
    mos: candidate.mos || "",
    yearsServed: candidate.yearsServed || "",
    targetIndustries: candidate.targetIndustries || "",
  });

  // Add current certification
  const addCurrentCertification = (certification) => {
    if (!currentCertifications.includes(certification)) {
      setCurrentCertifications((prev) => [...prev, certification]);
    }
  };

  // Add pursuing certification
  const addPursuingCertification = (certification) => {
    if (!pursuingCertifications.includes(certification)) {
      setPursuingCertifications((prev) => [...prev, certification]);
    }
  };

  // Handle input for current certifications
  const handleCurrentInput = (e) => {
    const input = e.target.value;
    setInputValue(input);

    if (input.length > 0) {
      const filteredSuggestions = certificationsList.filter((cert) =>
        cert.toLowerCase().includes(input.toLowerCase())
      );

      // Add "Create new:" suggestion if not in the list
      if (!filteredSuggestions.includes(input)) {
        filteredSuggestions.push(`Create new: ${input}`);
      }

      setCurrentSuggestions(filteredSuggestions);
    } else {
      setCurrentSuggestions([]);
    }
  };

  // Handle input for pursuing certifications
  const handlePursuingInput = (e) => {
    const input = e.target.value;
    setPursuingInputValue(input);

    if (input.length > 0) {
      const filteredSuggestions = certificationsList.filter((cert) =>
        cert.toLowerCase().includes(input.toLowerCase())
      );

      // Add "Create new:" suggestion if not in the list
      if (!filteredSuggestions.includes(input)) {
        filteredSuggestions.push(`Create new: ${input}`);
      }
      setPursuingSuggestions(filteredSuggestions);
    } else {
      setPursuingSuggestions([]);
    }
  };

  // Handle Enter key for adding current certifications
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && inputValue.trim() !== "") {
      addCurrentCertification(inputValue.trim());
      setInputValue("");
      setCurrentSuggestions([]);
    }
  };

  // Handle Enter key for adding pursuing certifications
  const handlePursuingKeyDown = (e) => {
    if (e.key === "Enter" && pursuingInputValue.trim() !== "") {
      addPursuingCertification(pursuingInputValue.trim());
      setPursuingInputValue("");
      setPursuingSuggestions([]);
    }
  };

  // Handle suggestion click for current certifications
  const handleCurrentSuggestionClick = (certification) => {
    if (certification.startsWith("Create new: ")) {
      const newCertification = certification.replace("Create new: ", "");
      addCurrentCertification(newCertification);
    } else {
      addCurrentCertification(certification);
    }
    setInputValue("");
    setCurrentSuggestions([]);
  };

  // Handle suggestion click for pursuing certifications
  const handlePursuingSuggestionClick = (certification) => {
    if (certification.startsWith("Create new: ")) {
      const newCertification = certification.replace("Create new: ", "");
      addPursuingCertification(newCertification);
    } else {
      addPursuingCertification(certification);
    }
    setPursuingInputValue("");
    setPursuingSuggestions([]);
  };

  // Remove current certification
  const handleRemoveCert = (index) => {
    const updatedCertifications = currentCertifications.filter(
      (_, i) => i !== index
    );
    setCurrentCertifications(updatedCertifications);
  };

  // Remove pursuing certification
  const handleRemovePursuingCert = (index) => {
    const updatedPursuingCertifications = pursuingCertifications.filter(
      (_, i) => i !== index
    );
    setPursuingCertifications(updatedPursuingCertifications);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        `http://localhost:5000/api/candidates/${candidate._id}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${user.token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...formData,
            certifications: currentCertifications,
            Pursuingcertifications: pursuingCertifications,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update candidate");
      }

      const updatedCandidate = await response.json();
      toast.success("Candidate updated successfully!");
      onClose();
      setTimeout(() => {
        window.location.reload(); // Optional: Reload page after update
      }, 1000);
    } catch (error) {
      toast.error("Error updating candidate: " + error.message);
    }
  };

  return (
    <>
      <ToastContainer />
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        className="certification-modal"
        overlayClassName="certification-modal-overlay"
      >
        <div className="certification-popup popup-content">
          <button onClick={onClose} className="close-btn">
            X
          </button>
          <div className="popup-header">
            <h2>Add or Edit Your Certifications</h2>
            <p>
              Keep this section updated to showcase your latest qualifications
              and stand out to employers.
            </p>
          </div>
          <hr />
          {/* Current Certifications Section */}
          <div className="certificate-heading">
            <h4>Your Current Certifications</h4>
            <input
              type="text"
              value={inputValue}
              onChange={handleCurrentInput}
              onKeyDown={handleKeyDown}
              placeholder="Type or search your certifications here"
            />
            {currentSuggestions.length > 0 && (
              <ul className="suggestions">
                {currentSuggestions.map((suggestion, index) => (
                  <li
                    key={index}
                    onClick={() => handleCurrentSuggestionClick(suggestion)}
                    className="suggestion-item"
                  >
                    {suggestion}
                  </li>
                ))}
              </ul>
            )}

            <div className="certifications-tags">
              {currentCertifications.map((cert, index) => (
                <span key={index} className="tag">
                  {cert}{" "}
                  <button onClick={() => handleRemoveCert(index)}>x</button>
                </span>
              ))}
            </div>
          </div>
          {/* Pursuing Certifications Section */}
          <div className="certificate-heading">
            <h4>Certifications You Are Currently Pursuing</h4>
            <input
              type="text"
              value={pursuingInputValue}
              onChange={handlePursuingInput}
              onKeyDown={handlePursuingKeyDown}
              placeholder="Type or search your certifications here"
            />
            {pursuingSuggestions.length > 0 && (
              <ul className="suggestions">
                {pursuingSuggestions.map((suggestion, index) => (
                  <li
                    key={index}
                    onClick={() => handlePursuingSuggestionClick(suggestion)}
                    className="suggestion-item"
                  >
                    {suggestion}
                  </li>
                ))}
              </ul>
            )}

            <div className="certifications-tags">
              {pursuingCertifications.map((cert, index) => (
                <span key={index} className="tag">
                  {cert}
                  {" (In-Progress)"}
                  <button onClick={() => handleRemovePursuingCert(index)}>
                    x
                  </button>
                </span>
              ))}
            </div>
          </div>
          <hr style={{ marginTop: "30px" }} />
          <div className="form-btn">
            <button onClick={handleSubmit}>Save Changes</button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddCertificate;
