export const ranks = {
  enlisted: [
    "Private (PVT/E-1)",
    "Private (PV2/E-2)",
    "Private First Class (PFC/E-3)",
    "Specialist (SPC/E-4)",
    "Corporal (CPL/E-4)",
    "Sergeant (SGT/E-5)",
    "Staff Sergeant (SSG/E-6)",
    "Sergeant First Class (SFC/E-7)",
    "Master Sergeant (MSG/E-8)",
    "First Sergeant (1SG/E-8)",
    "Sergeant Major (SGM/E-9)",
    "Command Sergeant Major (CSM/E-9)",
    "Sergeant Major of the Army (SMA/E-9S)",
    "Warrant Officer 1 (WO1/W-1)",
    "Chief Warrant Officer 2 (CW2/W-2)",
    "Chief Warrant Officer 3 (CW3/W-3)",
    "Chief Warrant Officer 4 (CW4/W-4)",
    "Chief Warrant Officer 5 (CW5/W-5)",
    "Second Lieutenant (2LT/O-1)",
    "First Lieutenant (1LT/O-2)",
    "Captain (CPT/O-3)",
    "Major (MAJ/O-4)",
    "Lieutenant Colonel (LTC/O-5)",
    "Colonel (COL/O-6)",
    "Brigadier General (BG/O-7)",
    "Major General (MG/O-8)",
    "Lieutenant General (LTG/O-9)",
    "General (GEN/O-10)",
    "General of the Army (GA/O-11)",
    "Private (Pvt/E-1)",
    "Private First Class (PFC/E-2)",
    "Lance Corporal (LCpl/E-3)",
    "Corporal (Cpl/E-4)",
    "Sergeant (Sgt/E-5)",
    "Staff Sergeant (SSgt/E-6)",
    "Gunnery Sergeant (GySgt/E-7)",
    "Master Sergeant (MSgt/E-8)",
    "First Sergeant (1stSgt/E-8)",
    "Master Gunnery Sergeant (MGySgt/E-9)",
    "Sergeant Major (SgtMaj/E-9)",
    "Sergeant Major of the Marine Corps (SMMC/E-9S)",
    "Warrant Officer (WO/W-1)",
    "Chief Warrant Officer 2 (CWO2/W-2)",
    "Chief Warrant Officer 3 (CWO3/W-3)",
    "Chief Warrant Officer 4 (CWO4/W-4)",
    "Chief Warrant Officer 5 (CWO5/W-5)",
    "Second Lieutenant (2ndLt/O-1)",
    "First Lieutenant (1stLt/O-2)",
    "Captain (Capt/O-3)",
    "Major (Maj/O-4)",
    "Lieutenant Colonel (LtCol/O-5)",
    "Colonel (Col/O-6)",
    "Brigadier General (BGen/O-7)",
    "Major General (MajGen/O-8)",
    "Lieutenant General (LtGen/O-9)",
    "General (Gen/O-10)",
    "Seaman Recruit (SR/E-1)",
    "Seaman Apprentice (SA/E-2)",
    "Seaman (SN/E-3)",
    "Petty Officer Third Class (PO3/E-4)",
    "Petty Officer Second Class (PO2/E-5)",
    "Petty Officer First Class (PO1/E-6)",
    "Chief Petty Officer (CPO/E-7)",
    "Senior Chief Petty Officer (SCPO/E-8)",
    "Master Chief Petty Officer (MCPO/E-9)",
    "Command Master Chief Petty Officer (CMDCM/E-9)",
    "Fleet Master Chief Petty Officer (FLTCM/E-9)",
    "Master Chief Petty Officer of the Navy (MCPON/E-9S)",
    "Chief Warrant Officer 2 (CWO2/W-2)",
    "Chief Warrant Officer 3 (CWO3/W-3)",
    "Chief Warrant Officer 4 (CWO4/W-4)",
    "Chief Warrant Officer 5 (CWO5/W-5)",
    "Ensign (ENS/O-1)",
    "Lieutenant Junior Grade (LTJG/O-2)",
    "Lieutenant (LT/O-3)",
    "Lieutenant Commander (LCDR/O-4)",
    "Commander (CDR/O-5)",
    "Captain (CAPT/O-6)",
    "Rear Admiral Lower Half (RDML/O-7)",
    "Rear Admiral (RADM/O-8)",
    "Vice Admiral (VADM/O-9)",
    "Admiral (ADM/O-10)",
    "Fleet Admiral (FADM/O-11)",
    "Airman Basic (AB/E-1)",
    "Airman (Amn/E-2)",
    "Airman First Class (A1C/E-3)",
    "Senior Airman (SrA/E-4)",
    "Staff Sergeant (SSgt/E-5)",
    "Technical Sergeant (TSgt/E-6)",
    "Master Sergeant (MSgt/E-7)",
    "Senior Master Sergeant (SMSgt/E-8)",
    "Chief Master Sergeant (CMSgt/E-9)",
    "Command Chief Master Sergeant (CCM/E-9)",
    "Chief Master Sergeant of the Air Force (CMSAF/E-9S)",
    "Second Lieutenant (2d Lt/O-1)",
    "First Lieutenant (1st Lt/O-2)",
    "Captain (Capt/O-3)",
    "Major (Maj/O-4)",
    "Lieutenant Colonel (Lt Col/O-5)",
    "Colonel (Col/O-6)",
    "Brigadier General (Brig Gen/O-7)",
    "Major General (Maj Gen/O-8)",
    "Lieutenant General (Lt Gen/O-9)",
    "General (Gen/O-10)",
    "General of the Air Force (GOAF/O-11)",
    "Specialist 1 (Spc1/E-1)",
    "Specialist 2 (Spc2/E-2)",
    "Specialist 3 (Spc3/E-3)",
    "Specialist 4 (Spc4/E-4)",
    "Sergeant (Sgt/E-5)",
    "Technical Sergeant (TSgt/E-6)",
    "Master Sergeant (MSgt/E-7)",
    "Senior Master Sergeant (SMSgt/E-8)",
    "Chief Master Sergeant (CMSgt/E-9)",
    "Chief Master Sergeant of the Space Force (CMSSF/E-9S)",
    "Second Lieutenant (2d Lt/O-1)",
    "First Lieutenant (1st Lt/O-2)",
    "Captain (Capt/O-3)",
    "Major (Maj/O-4)",
    "Lieutenant Colonel (Lt Col/O-5)",
    "Colonel (Col/O-6)",
    "Brigadier General (Brig Gen/O-7)",
    "Major General (Maj Gen/O-8)",
    "Lieutenant General (Lt Gen/O-9)",
    "General (Gen/O-10)",
    "Seaman Recruit (SR/E-1)",
    "Seaman Apprentice (SA/E-2)",
    "Seaman (SN/E-3)",
    "Petty Officer Third Class (PO3/E-4)",
    "Petty Officer Second Class (PO2/E-5)",
    "Petty Officer First Class (PO1/E-6)",
    "Chief Petty Officer (CPO/E-7)",
    "Senior Chief Petty Officer (SCPO/E-8)",
    "Master Chief Petty Officer (MCPO/E-9)",
    "Command Master Chief Petty Officer (CMDCM/E-9)",
    "Area Master Chief Petty Officer (AMCPO/E-9)",
    "Master Chief Petty Officer of the Coast Guard (MCPOCG/E-9S)",
    "Chief Warrant Officer 2 (CWO2/W-2)",
    "Chief Warrant Officer 3 (CWO3/W-3)",
    "Chief Warrant Officer 4 (CWO4/W-4)",
    "Chief Warrant Officer 5 (CWO5/W-5)",
    "Ensign (ENS/O-1)",
    "Lieutenant Junior Grade (LTJG/O-2)",
    "Lieutenant (LT/O-3)",
    "Lieutenant Commander (LCDR/O-4)",
    "Commander (CDR/O-5)",
    "Captain (CAPT/O-6)",
    "Rear Admiral Lower Half (RDML/O-7)",
    "Rear Admiral (RADM/O-8)",
    "Vice Admiral (VADM/O-9)",
    "Admiral (ADM/O-10)",
  ],
};
