import React, { useContext, useState } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import { Link } from "react-router-dom";
import logo from "../../../../assets/imgs/logo-7eagle.png";
import bellIcon from "../../../../assets/imgs/NotificationIcon.png"; // Notification Icon
import "../Header/Header.css"; // For Header component

const Header = ({ candidate }) => {
  const { logout, isAuthenticated } = useContext(AuthContext); // Get isAuthenticated from context
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const BASE_URL = `${process.env.REACT_APP_API_URL}`;
  // Use optional chaining and fallback for profileImage
  const profileImage = candidate?.headshotLink
    ? `${BASE_URL}/${candidate.headshotLink}`
    : null;

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <header className="header">
      <Link to="/candidate/dashboard">
        <img src={logo} alt="logo" className="logo-img" />
      </Link>
      {isAuthenticated && ( // Only render the nav if user is authenticated
        <nav>
          <ul>
            <Link to="/candidate/notifications">
              <img src={bellIcon} alt="bellIcon" className="bellIcon-img" />
            </Link>
            <li className="profile-menu" onClick={toggleDropdown}>
              <div>
                {candidate ? (
                  candidate.headshotLink ? (
                    <div className="exist-profile-image-header">
                      <img
                        src={profileImage}
                        alt="Profile"
                        style={{ marginRight: "10px" }}
                      />
                    </div>
                  ) : (
                    <div className="profile-image">
                      <h2>{candidate.name.charAt(0)}</h2>
                    </div>
                  )
                ) : (
                  <div className="profile-image">
                    <h2>?</h2>{" "}
                    {/* Placeholder for when candidate is not available */}
                  </div>
                )}
              </div>

              <span className="arrow">▼</span>
              {isDropdownOpen && (
                <div className="dropdown-menu-profile">
                  <button onClick={logout}>Logout</button>
                </div>
              )}
            </li>
          </ul>
        </nav>
      )}
    </header>
  );
};

export default Header;
