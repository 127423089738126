import React, { useContext, useState } from "react";
import "./EditAboutProfileInfo.css";
import { toast, ToastContainer } from "react-toastify"; // For displaying success/error messages
import { statesWithCities } from "./LocationList";
import { AuthContext } from "../../../../../context/AuthContext";
function CompleteProfileAbout({ candidate }) {
  const { user } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    fname: candidate.fname || "",
    lname: candidate.lname || "",
    candidateType: candidate.type || "",
    state: candidate.state || "",
    city: candidate.city || "",
    branch: candidate.branch || "",
    description: candidate.description || "",
    securityClearance: candidate.securityClearance || "",
    yearsOfExperience: candidate.yearsOfExperience || "",
    skillbridgeStartDate: candidate.skillbridgeStartDate || "",
    skillbridgeEndDate: candidate.skillbridgeEndDate || "",
    idealJobTitle: candidate.idealJobTitle || "",
    duringSkillbridgeCity: candidate.duringSkillbridgeCity || "",
    duringSkillbridgeState: candidate.duringSkillbridgeState || "",
    afterskillbridgeCity: candidate.afterskillbridgeCity || "",
    afterskillbridgeState: candidate.afterskillbridgeState || "",
    relocationprefrence: candidate.relocationprefrence || "",
    education: candidate.education || "",
    idno: candidate.recruiterFlowId || "", // Yeh ID RecruiterFlow ki hai
    email: candidate.email || "",
    phone: candidate.phone || "",
    certifications: candidate.certifications || "",
    Pursuingcertifications: candidate.Pursuingcertifications || "",
    branch: candidate.branch || "", // Optional chaining
    rank: candidate.rank || "",
    mos: candidate.mos || "",
    targetIndustries: candidate.targetIndustries || "",
    yearsServed: candidate.yearsServed || "",
  });

  const handleStateChange = (e) => {
    const selectedState = e.target.value;
    setFormData((prevData) => ({
      ...prevData,
      duringSkillbridgeState: selectedState,
      duringSkillbridgeCity: "", // Reset city when state is changed
    }));
  };

  const handleCityChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      duringSkillbridgeCity: e.target.value,
    }));
  };

  const handleAfterStateChange = (e) => {
    const selectedState = e.target.value;
    setFormData((prevData) => ({
      ...prevData,
      afterskillbridgeState: selectedState,
      afterskillbridgeCity: "", // Reset city when state is changed
    }));
  };

  const handleAfterCityChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      afterskillbridgeCity: e.target.value,
    }));
  };

  const securityClearances = ["Top Secret", "Secret", "Confidential", "None"];
  const yearOfExperiences = [
    "Less than 1 year",
    "1-3 years",
    "3-5 years",
    "5-10 years",
    "10+ years",
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        `http://localhost:5000/api/candidates/${candidate._id}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${user.token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData), // Pass the complete form data
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update candidate");
      }

      const updatedCandidate = await response.json();
      toast.success("Candidate updated successfully!");

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      toast.error("Error updating candidate: " + error.message);
    }
  };

  return (
    <div className="popup-content complete-profile-form">
      <ToastContainer />
      <div className="popup-header">
        <h2>Edit Your Profile Information</h2>
        <p>
          Give employers a better sense of who you are and what you're looking
          for. Highlight your background, ideal job, and availability to stand
          out!
        </p>
      </div>
      <hr />

      <div className="popup-form about-form">
        <div className="form-field">
          <label>
            Tell companies about yourself and your career goals.
            <span>*</span>
          </label>
          <textarea
            placeholder="Type Here"
            name="description"
            value={formData.description}
            onChange={handleChange}
          />
        </div>

        {/* Security Clearance - Years of Experience */}
        <div className="two-fields">
          <div className="form-field">
            <label>
              Security Clearance <span>*</span>
            </label>
            <select
              value={formData.securityClearance}
              onChange={handleChange}
              name="securityClearance"
            >
              <option value="">Select Option</option>
              {securityClearances.map((loc, index) => (
                <option key={index} value={loc}>
                  {loc}
                </option>
              ))}
            </select>
          </div>

          <div className="form-field">
            <label>
              Years of Experience <span>*</span>
            </label>
            <select
              value={formData.yearsOfExperience}
              onChange={handleChange}
              name="yearsOfExperience"
            >
              <option value="">Select Option</option>
              {yearOfExperiences.map((exp, index) => (
                <option key={index} value={exp}>
                  {exp}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="form-field">
          <label>
            Ideal Job <span>*</span>
          </label>
          <input
            type="text"
            placeholder="Type Here"
            name="idealJobTitle"
            value={formData.idealJobTitle}
            onChange={handleChange}
          />
        </div>

        {/* Check if candidate type is not 'Veteran' */}
        {candidate.type !== "Veteran" && (
          <>
            {/* SkillBridge Start Date - Skillbridge End date */}
            <div className="two-fields">
              <div className="form-field">
                <label>
                  Skillbridge Start Date <span>*</span>
                </label>
                <input
                  type="date"
                  name="skillbridgeStartDate"
                  value={formData.skillbridgeStartDate}
                  onChange={handleChange}
                />
              </div>

              <div className="form-field">
                <label>
                  Skillbridge End Date <span>*</span>
                </label>
                <input
                  type="date"
                  name="skillbridgeEndDate"
                  value={formData.skillbridgeEndDate}
                  onChange={handleChange}
                />
              </div>
            </div>

            {/* During SkillBridge */}
            <div className="form-field">
              <label>
                Do you know where you will live DURING SkillBridge?{" "}
                <span>*</span>
              </label>
            </div>

            {/* Location During Skillbridge */}
            <div className="two-fields">
              <div className="form-field">
                <select
                  name="duringSkillbridgeState"
                  value={formData.duringSkillbridgeState}
                  onChange={handleStateChange}
                >
                  <option value="">Select State</option>
                  {Object.keys(statesWithCities).map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
                <span className="sub-span">State</span>
              </div>
              <div className="form-field">
                <select
                  name="duringSkillbridgeCity"
                  value={formData.duringSkillbridgeCity}
                  onChange={handleCityChange}
                  disabled={!formData.duringSkillbridgeState} // Disable until state is selected
                >
                  <option value="">Select City</option>
                  {formData.duringSkillbridgeState &&
                    statesWithCities[formData.duringSkillbridgeState].map(
                      (city) => (
                        <option key={city} value={city}>
                          {city}
                        </option>
                      )
                    )}
                </select>
                <span className="sub-span">City</span>
              </div>
            </div>

            {/* After SkillBridge */}
            <div className="form-field">
              <label>
                Do you know where you will live AFTER SkillBridge?{" "}
                <span>*</span>
              </label>
            </div>

            {/* Location After Skillbridge */}
            <div className="two-fields">
              <div className="form-field">
                <select
                  name="afterskillbridgeState"
                  value={formData.afterskillbridgeState}
                  onChange={handleAfterStateChange}
                >
                  <option value="">Select State</option>
                  {Object.keys(statesWithCities).map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
                <span className="sub-span">State</span>
              </div>
              <div className="form-field">
                <select
                  name="afterskillbridgeCity"
                  value={formData.afterskillbridgeCity}
                  onChange={handleAfterCityChange}
                  disabled={!formData.afterskillbridgeState} // Disable until state is selected
                >
                  <option value="">Select City</option>
                  {formData.afterskillbridgeState &&
                    statesWithCities[formData.afterskillbridgeState].map(
                      (city) => (
                        <option key={city} value={city}>
                          {city}
                        </option>
                      )
                    )}
                </select>
                <span className="sub-span">City</span>
              </div>
            </div>
          </>
        )}

        {/* Relocation Preference */}
        <div className="form-field">
          <label>
            Are you open to relocating? <span>*</span>
          </label>
          <select
            name="relocationprefrence"
            value={formData.relocationprefrence}
            onChange={handleChange}
          >
            <option value="">Select Option</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>

        {/* Education */}
        <div className="form-field">
          <label>
            Education <span>*</span>
          </label>
          <input
            type="text"
            placeholder="Type Here"
            name="education"
            value={formData.education}
            onChange={handleChange}
          />
        </div>
      </div>

      <hr />
      <div className="form-btn">
        <button type="submit" className="update-btn" onClick={handleSubmit}>
          Update
        </button>
      </div>
    </div>
  );
}

export default CompleteProfileAbout;
