import React, { useEffect, useState } from "react";
import Modal from "react-modal"; // React Modal import
import "./UploadResume/UploadResume.css"; // Custom CSS for animation
import { toast, ToastContainer } from "react-toastify";
Modal.setAppElement("#root"); // Accessibility ke liye zaroori hai

const ViewResume = ({ isOpen, onClose, candidate }) => {
  const resumeUrl = candidate.resumeUrl ? candidate.resumeUrl : null;
  const BASE_URL = `${process.env.REACT_APP_API_URL}`;
  const resumeUrlLink = `${BASE_URL}/${candidate.resumeUrl}`;
  const profileUrlLink = `${BASE_URL}/${candidate.headshotLink}`;

  const [modalClass, setModalClass] = useState("modal-hidden");

  // Update modal class based on isOpen prop
  useEffect(() => {
    if (isOpen) {
      setModalClass("modal-visible");
    } else {
      setModalClass("modal-hidden");
    }
  }, [isOpen]);

  const customStyles = {
    content: {
      top: "50%",
      bottom: "auto",
      position: "absolute",
      borderRadius: "10px",
      padding: "0px",
      width: "35%",
      left: "65%",
      top: "0",
      minHeight: "100vh",
      transition: "transform 0.5s ease, opacity 0.5s ease", // Smooth slide and fade effect
      backgroundColor: "white",
    },
  };

  return (
    <div>
      <ToastContainer />
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        style={customStyles}
        overlayClassName="modal-overlay" // For background fade
        className={modalClass} // Applying animation class
      >
        <div className="popup-content">
          <button onClick={onClose} className="close-btn-resume">
            Close
          </button>

          <div className="popup-header">
            <div className="profile">
              {candidate.headshotLink ? (
                <img
                  src={profileUrlLink}
                  alt="headshot-img"
                  className="resume-img"
                />
              ) : (
                <div className="ProfileDefaultImg resume-default-img">
                  <h2>{candidate.name.charAt(0)}</h2>
                </div>
              )}

              <div className="profile-detail">
                <h2 className="resume-candidate-name">{candidate.name}</h2>
                <div style={{ display: "flex" }}>
                  <h6>{candidate.branch}</h6>
                  <span className="resume-type">{candidate.type}</span>
                </div>
              </div>
            </div>
          </div>

          <iframe
            src={resumeUrlLink}
            title="Resume"
            style={{
              width: "100%",
              height: "700px",
              border: "none",
              marginTop: "20px",
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default ViewResume;
