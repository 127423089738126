import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Ensure you have react-icons installed
import axios from "axios";
import otpImg from "../../../assets/imgs/otp-email.png";
import alertImg from "../../../assets/imgs/alert.png";
import trueImg from "../../../assets/imgs/true.png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import forgotImg from "../../../assets/imgs/forgot.png";

import "./CandidateRegister.css";
const ExistingRegistrationForm = ({
  step,
  formExistingData,
  handleExistingRegister,
  PreBuiltformData,
  showPassword,
  togglePasswordVisibility,
  existingCandidateName,
  existingCandidateEmail,

  handleOtpChange,
  setStep,
  handleExistingUserVerification,
  otp,
}) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleExistingCandidateRegister = async (e) => {
    e.preventDefault();

    // Check if new password matches confirm password
    if (password === confirmPassword) {
      try {
        // Send the email and new password to the backend API
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/users/existingUser-register`,
          {
            name: existingCandidateName,
            userType: "Candidate",
            email: existingCandidateEmail,
            newPassword: password,
            confirmPassword: confirmPassword,
            recruiterFlowId: PreBuiltformData.recruiterFlowId,
            profileUrl: PreBuiltformData.profileUrl,
            type: PreBuiltformData.type,
            branch: PreBuiltformData.branch,
            rank: PreBuiltformData.rank,
            mos: PreBuiltformData.mos,
            yearsServed: PreBuiltformData.yearsServed,
            description: PreBuiltformData.description,
            securityClearance: PreBuiltformData.securityClearance,
            skillbridgeStartDate: PreBuiltformData.skillbridgeStartDate,
            skillbridgeEndDate: PreBuiltformData.skillbridgeEndDate,
            locationDuringSkillbridge:
              PreBuiltformData.locationDuringSkillbridge,
            locationAfterSkillbridge: PreBuiltformData.locationAfterSkillbridge,
            veteranLocation: PreBuiltformData.veteranLocation,
            idealJobTitle: PreBuiltformData.idealJobTitle,
            targetIndustries: PreBuiltformData.targetIndustries,
            city: PreBuiltformData.city,
            state: PreBuiltformData.state,
            duringSkillbridgeCity: PreBuiltformData.duringSkillbridgeCity,
            duringSkillbridgeState: PreBuiltformData.duringSkillbridgeState,
            afterskillbridgeCity: PreBuiltformData.afterskillbridgeCity,
            afterskillbridgeState: PreBuiltformData.afterskillbridgeState,
            certifications: PreBuiltformData.certifications,

            Pursuingcertifications: PreBuiltformData.Pursuingcertifications,
            relocationprefrence: PreBuiltformData.relocationprefrence,
            yearsOfExperience: PreBuiltformData.yearsOfExperience,
            education: PreBuiltformData.education,
            resumeUrl: PreBuiltformData.resumeUrl,
            headshotLink: PreBuiltformData.headshotLink,
            videoIntroUrl: PreBuiltformData.videoIntroUrl,
            matchedCompanies: PreBuiltformData.matchedCompanies,
            favoriteCompanies: PreBuiltformData.favoriteCompanies,
          }
        );

        // Check for a successful response
        if (response.data.message === "User Registerd Successfuly") {
          setStep(4);
        } else {
          toast.error(
            "Unexpected response during complete registration process"
          );
        }
      } catch (error) {
        // Handle any error during the request
        if (error.response) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Updating failed. Please try again.");
        }
      }
    } else {
      // Handle password mismatch
      toast.error("Password does not match Confirm Password.");
    }
  };
  return (
    <form onSubmit={handleExistingRegister}>
      <div className="company-fields">
        {step === 2 && (
          <div className="2nd-step-fields otp-step">
            <div className="otp-img">
              <img src={otpImg} className="otp-img" alt="otp" />
            </div>
            <h2>Check Your Email</h2>
            <p className="otp-text">
              Please enter the 6 digit OTP sent to your email.
            </p>
            <div
              className="otp-inputs"
              style={{ display: "flex", justifyContent: "center", gap: "10px" }}
            >
              {otp.map((digit, index) => (
                <input
                  key={index}
                  type="text"
                  maxLength="1"
                  value={digit}
                  onChange={(e) => handleOtpChange(e.target, index)}
                  style={{
                    width: "50px",
                    height: "50px",
                    textAlign: "center",
                    fontSize: "24px",
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                  }}
                />
              ))}
            </div>
            <p style={{ padding: "20px 0px 0px 0px" }}>
              Didn’t receive an email?{" "}
              <span style={{ fontWeight: 500, cursor: "pointer" }}>
                Resend Email
              </span>
            </p>
            <div name="prev-next-buttons">
              <button
                className="btn confirm-btn"
                onClick={handleExistingUserVerification}
              >
                Confirm Account
              </button>
            </div>
          </div>
        )}

        {step === 3 && (
          <div className="already-registed-box">
            <img src={alertImg} className="alertImg" alt="alert-img" />
            <h3>Account Already Registered</h3>
            <p>
              An admin account for <b> {formExistingData.companyname} </b>
              already exists. Please contact your admin{" "}
              <b>{formExistingData.email}</b> to be added as a user and join the
              team.
            </p>
          </div>
        )}

        {step === 4 && (
          <div className="done-registed-box">
            <img src={trueImg} className="trueImg" alt="trueimg" />
            <h3>Account Successfully Registered</h3>
            <p>You can now start using your account!</p>
            <div>
              <Link to="/candidate/login" className="Login-btn-done">
                Login Your Account!
              </Link>
            </div>
          </div>
        )}

        {step === 6 && (
          <>
            <img src={forgotImg} alt="forgotimg" className="alertImg" />
            <h2 style={{ marginBottom: "10px" }}>Set A New Password</h2>
            <p style={{ textAlign: "center" }}>
              Enter a new password below to update your account.
            </p>

            <div className="password-field">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                style={{ marginBottom: "10px" }}
              />

              <span
                onClick={togglePasswordVisibility}
                className="password-toggle-icon"
              >
                {showPassword ? (
                  <FaEyeSlash size={24} color="#7D8287" />
                ) : (
                  <FaEye size={24} color="#7D8287" />
                )}
              </span>
            </div>

            <div className="password-field">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />

              <span
                onClick={togglePasswordVisibility}
                className="password-toggle-icon"
              >
                {showPassword ? (
                  <FaEyeSlash size={24} color="#7D8287" />
                ) : (
                  <FaEye size={24} color="#7D8287" />
                )}
              </span>
            </div>

            <button
              className="existinguserRegisterbtn"
              style={{ marginTop: "10px" }}
              onClick={handleExistingCandidateRegister}
            >
              Continue
            </button>
            <div
              style={{
                marginTop: "15px",
                textAlign: "center",
                color: "gray",
              }}
            >
              <Link
                to="/candidate/login"
                style={{ color: "gray", fontSize: "14px" }}
              >
                Login Back
              </Link>
            </div>
          </>
        )}
      </div>
    </form>
  );
};

export default ExistingRegistrationForm;
