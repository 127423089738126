import React, { useState, useContext } from "react";
import "./JobSearchStatus.css";
import plusIcon from "../../../../assets/imgs/Edit.png";
import { AuthContext } from "../../../../context/AuthContext";
import UpdateJobSearchSlider from "./UpdateJobSearchSlider";

function JobSearchStatus({ candidate }) {
  const { user } = useContext(AuthContext);

  // Modal ko handle karne ke liye state
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Modal open karne ka function
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Modal band karne ka function
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="basicInformation">
      <h4>Job Search Status</h4>

      <div className="job-status">
        <h4 className="job_search_number">{candidate.jobSearchStatus}</h4>
        <p>{candidate.jobSearchStatusName}</p>
      </div>
      {/* Edit icon pe click karke modal open karte hain */}
      <div className="editIcon" onClick={openModal}>
        <img src={plusIcon} alt="plus-icon" />
      </div>

      <UpdateJobSearchSlider
        isOpen={isModalOpen}
        onClose={closeModal}
        candidate={candidate}
      />
    </div>
  );
}

export default JobSearchStatus;
