export const certificationsList = [
  "CompTIA Security+",
  "CompTIA Network+",
  "CompTIA A+",
  "Certified Information Systems Security Professional (CISSP)",
  "Cisco Certified Network Associate (CCNA)",
  "Cisco Certified Network Professional (CCNP)",
  "Microsoft Certified Systems Engineer (MCSE)",
  "Microsoft Certified Solutions Expert (MCSE)",
  "VMware Certified Professional (VCP)",
  "Certified Ethical Hacker (CEH)",
  "CompTIA Linux+",
  "Certified Wireless Network Administrator (CWNA)",
  "Cisco Certified Design Associate (CCDA)",
  "Cisco Certified Design Professional (CCDP)",
  "Certified Forensic Computer Examiner (CFCE)",
  "Certified Information Systems Forensics Investigator (CISFI)",
  "GIAC Certified Intrusion Analyst (GCIA)",
  "Certified Virtualization Expert (CVE)",
  "Information Assurance Technician (IAT) Level I, II, III (DoD 8570)",
  "Certified Defense Cybersecurity Professional (CDCP)",
  "Certified Information Security Manager (CISM)",
  "Certified Information Systems Auditor (CISA)",
  "GIAC Security Essentials (GSEC)",
  "Certified Information Assurance Manager (IAM)",
  "Certified Information Assurance Officer (CIAO)",
  "Department of Defense (DoD) Information Assurance Certification and Accreditation (DIACAP)",
  "Information Systems Security Engineering Professional (ISSEP)",
  "Global Industrial Cyber Security Professional (GICSP)",
  "Certified Cloud Security Professional (CCSP)",
  "DoD Cyber Awareness Challenge",
  "Project Management Professional (PMP)",
  "Certified Associate in Project Management (CAPM)",
  "Six Sigma Green Belt",
  "Lean Six Sigma",
  "Scrum Master",
  "Agile Certified Practitioner (PMI-ACP)",
  "Emergency Medical Technician (EMT)",
  "Certified Medical Assistant (CMA)",
  "Certified Nursing Assistant (CNA)",
  "Combat Lifesaver Certification (CLS)",
  "Tactical Combat Casualty Care (TCCC)",
  "Field Sanitation Team Certification",
  "Preventive Medicine Specialist Certification",
  "Combat Medic Specialist",
  "Flight Paramedic Certification",
  "Basic Life Support (BLS)",
  "Advanced Cardiac Life Support (ACLS)",
  "Certified Supply Chain Professional (CSCP)",
  "Certified Professional in Supply Management (CPSM)",
  "Certified Logistics Technician",
  "Defense Logistics Agency (DLA) Certified Logistics Technician",
  "Army Logistics University (ALU) Certified Logistician",
  "Global Combat Support System-Army (GCSS-Army)",
  "U.S. Army Transportation Management Coordinator",
  "Certified Joint Logistics Planner (CJLP)",
  "U.S. Army Aviation School Certified Maintenance Test Pilot",
  "U.S. Air Force Aircraft Armament Systems Specialist",
  "Military Aviation Flight Instructor Certification",
  "Air Traffic Control Specialist (Military)",
  "FAA Airframe and Powerplant (A&P) Certification",
  "Military Aircraft Electrician Certification",
  "Certified Armament Technician",
  "U.S. Army Corps of Engineers (USACE) Certifications",
  "Navy Nuclear Power Program Certifications (Navy Nuke)",
  "Certified Military Electrician",
  "Military Explosive Ordnance Disposal (EOD) Certification",
  "Certified Vehicle Recovery Specialist",
  "Military Generator Mechanic Certification",
  "U.S. Army Wheeled Vehicle Mechanic Certification",
  "Heavy Equipment Operator Certification",
  "Military Maintenance Management Specialist",
  "U.S. Army Non-Commissioned Officer (NCO) Professional Development Certification",
  "U.S. Army Advanced Leader Course (ALC)",
  "Senior Leader Course (SLC)",
  "First Sergeant Course (1SG)",
  "Officer Candidate School (OCS) Graduate Certification",
  "Senior Enlisted Joint Professional Military Education (SEJPME)",
  "U.S. Army Ranger School Certification",
  "U.S. Army Sniper Certification",
  "Airborne School Certification",
  "Air Assault School Certification",
  "SERE (Survival, Evasion, Resistance, and Escape) Certification",
  "Military Freefall Parachutist Certification",
  "U.S. Navy SEAL Qualification",
  "U.S. Marine Corps Scout Sniper Certification",
  "Military Police Investigator Certification",
  "U.S. Army Criminal Investigation Division (CID) Certification",
  "Anti-Terrorism Officer (ATO) Certification",
  "Military Working Dog Handler Certification",
  "Protective Services Training Course (PSTC)",
  "U.S. Army Intelligence School Certifications",
  "U.S. Navy Cryptologic Technician Certification",
  "U.S. Air Force Network Intelligence Analyst",
  "Certified Geospatial Intelligence Analyst",
  "Military Hazardous Materials Certification",
  "U.S. Army Environmental Compliance Officer Certification",
  "Hazardous Waste Operations and Emergency Response (HAZWOPER)",
  "U.S. Army Firefighter Certification",
  "Military Fire Protection Specialist Certification",
  "U.S. Army Small Arms Master Gunner Certification",
  "Military Advanced Marksmanship Instructor Certification",
  "Military Arms Room Supervisor Certification",
];
