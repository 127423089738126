import React, { useState } from "react";
import "./MilitaryBackground.css";
import militaryIcon from "../../../../assets/imgs/military.png";
import plusIcon from "../../../../assets/imgs/plusCircle.png";
import AddMilitary from "./Add Military/AddMilitary";
import editIcon from "../../../../assets/imgs/Edit.png";
function MilitaryBackground({ candidate, hideEditIcon }) {
  // Modal ko handle karne ke liye state
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Modal open karne ka function
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Modal band karne ka function
  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div className="target_industries">
      <h4>Military Background</h4>

      <div>
        {!candidate.mos &&
        !candidate.rank &&
        !candidate.yearsServed &&
        !candidate.branch ? (
          // Show the empty-state if all values are empty
          <div className="empty-state">
            <div className="plusIcon" onClick={openModal}>
              <img src={plusIcon} alt="plus-icon" />
            </div>
            <img src={militaryIcon} alt="Military Icon" />
            <p>
              Your military service is valuable! Fill in your branch, rank, and
              MOS to help employers understand your background.
            </p>
            <button onClick={openModal}>Add Military Background</button>
          </div>
        ) : (
          // Show the exist-state if any value exists
          <div className="exist-state">
            {!hideEditIcon && (
              <div className="editIcon" onClick={openModal}>
                <img src={editIcon} alt="Edit-icon" />
              </div>
            )}
            <div className="military-values">
              <div className="branch-value">
                <p>Military Branch</p>
                <span>
                  <b>{candidate.branch}</b>
                </span>
              </div>

              <div className="branch-value">
                <p>Rank</p>
                <span>
                  <b>{candidate.rank}</b>
                </span>
              </div>

              <div className="branch-value">
                <p>MOS</p>
                <span>
                  <b>{candidate.mos}</b>
                </span>
              </div>

              <div className="branch-value">
                <p>Years Served</p>
                <span>
                  <b>{candidate.yearsServed}</b>
                </span>
              </div>
            </div>
          </div>
        )}
      </div>

      <AddMilitary
        isOpen={isModalOpen}
        onClose={closeModal}
        candidate={candidate}
      />
    </div>
  );
}

export default MilitaryBackground;
