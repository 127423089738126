// Sidebar.js
import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import profileImage from "../../../../assets/imgs/userImg.png";
// import badgeIcon from "../../../../assets/imgs/BadgeIcon.png";
import dashboardIcon from "../../../../assets/imgs/userProfile.png";
import searchIcon from "../../../../assets/imgs/formIcon.png";
import resourcesIcon from "../../../../assets/imgs/Book_open_fill.png";
import matchIcon from "../../../../assets/imgs/browse.png";
import sentIcon from "../../../../assets/imgs/job.png";
import favoriteIcon from "../../../../assets/imgs/dashboardCandidate.png";
import collapsedIcon from "../../../../assets/imgs/collapse.png";
import { AuthContext } from "../../../../context/AuthContext";
import "../Sidebar/Sidebar.css";

const Sidebar = ({ onToggleSidebar, candidate }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { user } = useContext(AuthContext);

  const BASE_URL = `${process.env.REACT_APP_API_URL}`;
  const profileImage = `${BASE_URL}/${candidate.headshotLink}`;
  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
    onToggleSidebar(!isCollapsed); // Pass the updated state to Dashboard.js
  };

  return (
    <aside className={isCollapsed ? "collapsed" : ""}>
      <div className="profile-box">
        <Link to="/candidate/dashboard">
          <div className="profile-box-wrap">
            <div className="img-box">
              <div>
                {candidate.headshotLink ? (
                  <div className="exist-profile-image">
                    <img
                      src={profileImage}
                      alt="Company logo"
                      className="company-logo-img"
                    />
                  </div>
                ) : (
                  <div className="ProfileDefaultImg sidebardefaultProfile">
                    <h2>{candidate.name.charAt(0)}</h2>
                  </div>
                )}
              </div>
            </div>
            {!isCollapsed && (
              <div className="company-name">
                <h6>{candidate.name}</h6>
                {/* <img src={badgeIcon} alt="icon-badge" className="badgeIcon" /> */}
              </div>
            )}
          </div>
        </Link>
      </div>
      <ul>
        <li className="active">
          <Link to="/candidate/dashboard">
            <img
              src={dashboardIcon}
              className="sidebar-icons"
              alt="dashbordicon"
            />
            {!isCollapsed && "My Profile"}
          </Link>
        </li>

        <li>
          <Link to="/candidate/dashboard">
            <img
              src={resourcesIcon}
              className="sidebar-icons"
              alt="resourseicon"
            />
            {!isCollapsed && "Resources"}
          </Link>
        </li>

        <li>
          <Link to="/candidate/dashboard">
            <img src={searchIcon} className="sidebar-icons" alt="searchicon" />
            {!isCollapsed && "Forms"}
          </Link>
        </li>

        {!isCollapsed && (
          <h6 style={{ paddingTop: "10px", color: "#7d8288" }}>
            COMMING SOON ...
          </h6>
        )}
        <li>
          <Link to="/candidate/dashboard">
            <img
              src={favoriteIcon}
              className="sidebar-icons"
              alt="favoriteicon"
            />
            {!isCollapsed && "Favorite Candidates"}
          </Link>
        </li>
        <li>
          <Link to="/candidate/dashboard">
            <img src={matchIcon} className="sidebar-icons" alt="matchicon" />
            {!isCollapsed && "Browse Companies"}
          </Link>
        </li>

        <li>
          <Link to="/candidate/dashboard">
            <img src={sentIcon} className="sidebar-icons" alt="senticon" />
            {!isCollapsed && "Browse Jobs"}
          </Link>
        </li>
      </ul>
      <div className="toggle-btn">
        <div
          onClick={toggleSidebar}
          className="collapse-btn"
          style={{ cursor: "pointer" }}
        >
          {isCollapsed ? (
            <img src={collapsedIcon} alt="expand icon" />
          ) : (
            <>
              <img
                src={collapsedIcon}
                className="sidebar-icons"
                alt="collapse icon"
              />{" "}
              Collapse Menu{" "}
            </>
          )}
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
