import React, { useState, useEffect } from "react";
import axios from "axios";
import "./CandidateRegister.css";
import { useNavigate, useLocation } from "react-router-dom";
import loginLogo from "../../../assets/imgs/7eagle-logo.png";
import bgImg from "../../../assets/imgs/bg.png";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../../candidate/dashboard/Header/Header";
import RegistrationForm from "./CandidateRegistratonForm";
import ExistingRegistrationForm from "./ExistingRegistrationForm";

const Register = () => {
  // State For New Company
  const [step, setStep] = useState(1);
  const [showPassword, setShowPassword] = useState(false);
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [formData, setFormData] = useState({
    companyname: "",
    fname: "",
    lname: "",
    title: "",
    email: "",
    phone: "",
    password: "",
    userType: "Candidate",
    candidateType: "",
    branch: "",
  });

  const [PreBuiltformData, setPrebuiltFormData] = useState({});
  useEffect(() => {
    console.log("Updated Data", PreBuiltformData);
  }, [PreBuiltformData]); // This will log whenever PreBuiltformData changes

  const [isCandidate, setIsCandidate] = useState(true);

  // State For Existing Company

  const [showExistingForm, setShowExistingForm] = useState(false);
  const [existingUser, setExistingUser] = useState(null); // Initially set to null
  const [existingCandidateName, setExistingCandidateName] = useState("");
  const [existingCandidateEmail, setExistingCandidateEmail] = useState("");

  const [existingCandidateData, setExistingCandidateData] = useState("");

  const [existingCandidateImg, setExistingCandidateImg] = useState("");

  const navigate = useNavigate();
  const location = useLocation();

  const movetoRegister = () => {
    navigate("/candidate/register"); // Navigate to the specified route
  };
  // Existing Company Functions
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const candidateId = queryParams.get("candidateId");

    if (candidateId) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/prebuiltcandidates/prebuilt/${candidateId}`
        )
        .then((response) => {
          if (response.data) {
            setExistingUser(true);

            setExistingCandidateName(response.data.name);
            setExistingCandidateEmail(response.data.email);
            setExistingCandidateImg(response.data.profileUrl);
            setExistingCandidateData(response.data);

            setPrebuiltFormData(response.data);
            console.log(PreBuiltformData);
          } else {
            navigate("/candidate/register");
          }
        })
        .catch((error) => {
          console.error("Error fetching candidate data:", error);
          navigate("/candidate/register");
        });
    } else {
      setExistingUser(false); // If no candidateId in query params, set to false
    }
  }, [location.search, navigate]);

  const [formExistingData, setFormExistingData] = useState({
    fname: "",
    lname: "",
    title: "",
    email: "",
    phone: "",
    password: "",
    userType: "Candidate",
    candidateType: "",
    branch: "",
  });

  const handleExistingUserVerification = async (e) => {
    e.preventDefault();

    const otpCode = otp.join("");

    try {
      const otpResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/users/verify-existing-user`,
        {
          email: existingCandidateEmail,
          otp: otpCode,
        }
      );

      if (otpResponse.data.message === "Correct Otp") {
        toast.success(otpResponse.data.message);
        setStep(6);
      } else {
        toast.error("Unexpected response during registration.");
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Verification failed. Please try again.");
      }
    }
  };

  const handleExistingRegister = async (e) => {
    e.preventDefault();

    const otpCode = otp.join("");

    try {
      const otpResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/users/verify-otp-register`,
        {
          email: formExistingData.email,
          otp: otpCode,
          password: formExistingData.password,
          userType: formExistingData.userType,
          fname: formExistingData.fname,
          lname: formExistingData.lname,
          phone: formExistingData.phone,
          logo: existingCandidateData.logo,
          city: existingCandidateData.city,
          companyImages: existingCandidateData.companyImages,
          companySize: existingCandidateData.companySize,
          companyWebsite: existingCandidateData.companyWebsite,
          coverPhoto: existingCandidateData.coverPhoto,
          description: existingCandidateData.description,
          hqLocation: existingCandidateData.hqLocation,
          industry: existingCandidateData.industry,
          linkedinUrl: existingCandidateData.linkedinUrl,
          profileUrl: existingCandidateData.profileUrl,
          remoteOpportunity: existingCandidateData.remoteOpportunity,
          state: existingCandidateData.state,
          veteranOwned: existingCandidateData.veteranOwned,
          videoOverview: existingCandidateData.videoOverview,
        }
      );

      if (otpResponse.data.message === "User registered successfully") {
        toast.success(otpResponse.data.message);

        setFormData({
          companyname: "",
          fname: "",
          lname: "",
          title: "",
          email: "",
          phone: "",
          password: "",
          userType: "Company",
        });

        setStep(4);
      } else {
        toast.error("Unexpected response during registration.");
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
        if (
          error.response.data.message === "User already exists with this email"
        ) {
          setStep(3);
        }
        if (error.response.data.message === "Company already exists") {
          setStep(3);
        }
      } else {
        toast.error("Registration failed. Please try again.");
      }
    }
  };

  const sendOtpExistingCandidate = async () => {
    if (!formExistingData.userType || !existingCandidateEmail) {
      toast.error("Please fill out all required fields.");
      return;
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/users/send-otp`,
        { email: existingCandidateEmail }
      );
      toast.success(response.data.message);
      setShowExistingForm(true);
      setStep(2);
    } catch (error) {
      toast.error(
        error.response ? error.response.data.message : "Failed to send OTP"
      );
    }
  };

  // Common Functions
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setFormExistingData({ ...formExistingData, [name]: value });
  };

  const handleOtpChange = (element, index) => {
    let otpArray = [...otp];
    otpArray[index] = element.value;
    setOtp(otpArray);

    if (element.nextSibling && element.value) {
      element.nextSibling.focus();
    }
  };

  const sendOtp = async () => {
    if (step === 1) {
      if (
        !formData.email ||
        !formData.userType ||
        !formData.phone ||
        !formData.fname ||
        !formData.lname ||
        !formData.userType ||
        !formData.branch ||
        !formData.candidateType ||
        !formData.password
      ) {
        toast.error("Please fill out all required fields.");
        return;
      }
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/users/send-otp`,
          { email: formData.email }
        );
        toast.success(response.data.message);
        setStep(2);
      } catch (error) {
        toast.error(
          error.response ? error.response.data.message : "Failed to send OTP"
        );
      }
    }
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Candidate Register Form Manualy

  const handleRegister = async (e) => {
    e.preventDefault();

    const otpCode = otp.join("");

    try {
      const otpResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/users/verify-otp-register`,
        {
          email: formData.email,
          otp: otpCode,
          password: formData.password,
          userType: formData.userType,
          fname: formData.fname,
          lname: formData.lname,
          phone: formData.phone,
          companyname: formData.companyname,
          branch: formData.branch,
          candidateType: formData.candidateType,
        }
      );

      if (otpResponse.data.message === "User registered successfully") {
        toast.success(otpResponse.data.message);

        setFormData({
          companyname: "",
          fname: "",
          lname: "",
          title: "",
          email: "",
          phone: "",
          password: "",
          userType: "",
        });

        setStep(4);
      } else {
        toast.error("Unexpected response during registration.");
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
        if (
          error.response.data.message === "User already exists with this email"
        ) {
          setStep(3);
        }

        if (error.response.data.message === "Candidate already exists") {
          setStep(3);
        }
      } else {
        toast.error("Registration failed. Please try again.");
      }
    }
  };

  return (
    <>
      <Header />
      <ToastContainer />
      <div className="candidate-register-container">
        <div className="row register-row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="register-banner">
              <img
                src={bgImg}
                alt="company-banner"
                className="leftSidebgRegister"
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="form main-box">
              {existingUser === null ? ( // Render loading or nothing while determining existingUser
                <></>
              ) : existingUser ? (
                <>
                  {!showExistingForm && (
                    <div className="existing-company-box">
                      <img
                        src={loginLogo}
                        alt="company-logo"
                        className="company-logo"
                      />
                      <h4>Welcome to 7 Eagle Group</h4>

                      <p style={{ textAlign: "center" }}>
                        Set up your profile to be seen by thousands of hiring
                        companies and access exclusive resources to boost your
                        marketability. More features like browsing jobs and
                        companies are coming soon!
                      </p>

                      <input
                        type="email"
                        value={existingCandidateEmail}
                        onChange={handleChange}
                        className="existingemailfield"
                        readOnly
                      />

                      <button
                        className="existingCompany-btn"
                        onClick={sendOtpExistingCandidate}
                      >
                        Get Started
                      </button>
                      <p
                        style={{
                          margin: "10px",
                          textAlign: "center",
                          fontWeight: 500,
                          fontSize: "14px",
                          cursor: "pointer",
                        }}
                        onClick={movetoRegister}
                      >
                        Not You?
                      </p>
                    </div>
                  )}

                  {showExistingForm && (
                    <div className="existing-company-form">
                      <ExistingRegistrationForm
                        step={step}
                        PreBuiltformData={PreBuiltformData}
                        formExistingData={formExistingData}
                        handleChange={handleChange}
                        handleExistingRegister={handleExistingRegister}
                        showPassword={showPassword}
                        togglePasswordVisibility={togglePasswordVisibility}
                        prevStep={prevStep}
                        handleOtpChange={handleOtpChange}
                        otp={otp}
                        setStep={setStep}
                        existingCandidateName={existingCandidateName}
                        existingCandidateEmail={existingCandidateEmail}
                        handleExistingUserVerification={
                          handleExistingUserVerification
                        }
                      />
                    </div>
                  )}
                </>
              ) : (
                <>
                  {isCandidate && (
                    <RegistrationForm
                      step={step}
                      formData={formData}
                      handleChange={handleChange}
                      handleRegister={handleRegister}
                      showPassword={showPassword}
                      togglePasswordVisibility={togglePasswordVisibility}
                      sendOtp={sendOtp}
                      prevStep={prevStep}
                      handleOtpChange={handleOtpChange}
                      otp={otp}
                      setStep={setStep}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
